import { useRecruiterAllQuery } from "../../../core/api/vacancy/vacancy";
import useErrorNotification from "../../../hooks/useErrorNotification";
import style from "./CreateOrUpdateVacancy.module.scss";
import { RecruiterProps } from "./type";
import { Input, Select, SelectData } from "@aurora/components";
import cx from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RecruiterData: React.FC<RecruiterProps> = ({ setVacancy, vacancy }) => {
  const errorNotification = useErrorNotification();
  const [email, setEmail] = useState("");
  const [selected, setSelected] = useState<SelectData | null>(null);

  const user = useSelector((store: any) => store.userDataSlice.user);

  const {
    data: RecruiterData,
    error: RecruiterError,
    isLoading: isRecruiterLoading,
  } = useRecruiterAllQuery();
  const selectLabel = (
    <span>
      Рекрутер <span className={"text-critical-base"}>*</span>
    </span>
  );
  const inputLabel = <span>Электронная почта рекрутера</span>;

  useEffect(() => {
    if (user.category === "RECRUITER") {
      onSelectChange({ email: user.email, id: user.id, value: user.fullName });
    }
  }, []);

  useEffect(() => {
    if (RecruiterError) {
      const err = RecruiterError as { data: any };
      errorNotification(err);
    }
  }, [RecruiterError]);

  useEffect(() => {
    if (
      !isRecruiterLoading &&
      !RecruiterError &&
      vacancy.recruiterId &&
      RecruiterData?.some(r => r.id === vacancy.recruiterId)
    ) {
      const recruiter = RecruiterData.filter(
        r => r.id === vacancy.recruiterId,
      )[0];
      setSelected({ id: vacancy.recruiterId, value: recruiter.fullName });
      setEmail(recruiter.email);
    }
  }, [RecruiterData, isRecruiterLoading]);

  function onSelectChange(recruiter: any) {
    setSelected(recruiter as SelectData);
    setEmail(recruiter.email);
    setVacancy({ ...vacancy, recruiterId: recruiter.id });
  }

  return (
    <>
      {!isRecruiterLoading && !RecruiterError ? (
        <div className={cx("mt-16", style.CreateOrUpdateVacancyFormField)}>
          <div>
            <Select
              className={cx("p-0")}
              data={RecruiterData as SelectData[]}
              label={selectLabel}
              onChange={onSelectChange}
              shape="autocomplite"
              value={selected}
            />
          </div>
          <div>
            <Input
              disabled={true}
              label={inputLabel}
              placeholder=""
              value={email}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RecruiterData;
