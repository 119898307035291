import { useLabelsByPartTextQuery } from "../../../../core/api/base/other";
import {
  useVacationAutocompleteTitleQuery,
  useVacationAutocompleteRecruiterQuery,
} from "../../../../core/api/vacancy/vacancy";
import { formatISODate } from "../../../../services/date.service";
import { IRequestSearchByFilter } from "../types";
import { LabelSearchModel } from "../types";
import style from "./FilterSideBar.module.scss";
import {
  Button,
  Checkbox,
  InputDatepicker,
  Multiselect,
  MultiselectData,
  Radio,
  RadioFormGroup,
} from "@aurora/components";
import { GlyphSearch } from "@aurora/icons";
import cx from "classnames";
import { useCandidateAutocompleteQuery } from "core/api/candidate/candidate";
import { FC, useEffect, useState } from "react";

interface ICandidatesProps {
  setQueryParams: (params: any) => void;
}

interface State {
  CandidateField: string;
  datepickerValue: (Date | null)[];
  has_new_message: boolean;
}

function initialState(): State {
  return {
    CandidateField: "",
    datepickerValue: [null, null],
    has_new_message: false,
  };
}

const FilterSideBar: FC<ICandidatesProps> = ({ setQueryParams }) => {
  const [state, setState] = useState(initialState);
  const [needClear, setNeedClear] = useState(false);
  const [isFirstLoad, changeFirstLoad] = useState(true);

  const [autocompleteCandidate, changeAutocompleteCandidate] = useState("");
  const [autocompleteVacancy, setAutocompleteVacancy] = useState("");
  const [autocompleteRecruiter, setAutocompleteRecruiter] = useState("");
  const [candidateData, setCandidateData] = useState<MultiselectData[]>([]);
  const [vacancyData, setVacancyData] = useState<MultiselectData[]>([]);
  const [recruiterData, setRecruiterData] = useState<MultiselectData[]>([]);
  const [labelAutocomplete, setLabelAutocomplete] = useState("");
  const [labelData, setLabelData] = useState<MultiselectData[]>([]);
  const [labelSearchLogic, setLabelSearchLogic] =
    useState<LabelSearchModel>("OR");
  const {
    data: candidateAutocompleteData,
    error: candidateAutocompleteError,
    isLoading: candidateAutocompleteLoading,
  } = useCandidateAutocompleteQuery(
    { query: autocompleteCandidate },
    {
      skip: autocompleteCandidate.length < 3,
    },
  );
  const {
    data: vacancyAutocompleteData,
    error: vacancyAutocompleteError,
    isLoading: vacancyAutocompleteLoading,
  } = useVacationAutocompleteTitleQuery(autocompleteVacancy, {
    skip: autocompleteVacancy.length < 3,
  });
  const {
    data: recruiterAutocompleteData,
    error: recruiterAutocompleteError,
    isLoading: recruiterAutocompleteLoading,
  } = useVacationAutocompleteRecruiterQuery(autocompleteRecruiter, {
    skip: autocompleteRecruiter.length < 3,
  });
  const {
    data: labelAutocompleteData,
    error: labelAutocompleteError,
    isLoading: labelAutocompleteLoading,
  } = useLabelsByPartTextQuery(labelAutocomplete, {
    skip: labelAutocomplete.length < 3,
  });

  useEffect(() => {
    if (candidateAutocompleteError)
      console.error("candidateAutocompleteError", candidateAutocompleteError);
    if (vacancyAutocompleteError)
      console.error("vacancyAutocompleteError", vacancyAutocompleteError);
    if (recruiterAutocompleteError)
      console.error("recruiterAutocompleteError", recruiterAutocompleteError);
    if (labelAutocompleteError)
      console.error("labelAutocompleteError", labelAutocompleteError);
  }, [
    candidateAutocompleteError,
    vacancyAutocompleteError,
    recruiterAutocompleteError,
    labelAutocompleteError,
  ]);

  const handleChange = (field: keyof State, value: any) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleSubmit = () => {
    if (isFirstLoad) {
      changeFirstLoad(false);
      return;
    }
    setQueryParams((oldParams: IRequestSearchByFilter) => {
      return {
        ...oldParams,
        has_new_message: state.has_new_message,
        labelIds: labelData.map(label => label.id),
        labelSearchMode: labelSearchLogic,
        pageNum: 1,
        personNames: candidateData.map(candidate => candidate.value),
        recruiterIds: recruiterData.map(recruiter => recruiter.id),
        resumeUploadPeriodEnd: formatISODate(state.datepickerValue[1]),
        resumeUploadPeriodStart: formatISODate(state.datepickerValue[0]),
        vacancyIds: vacancyData.map(vacancy => vacancy.id),
      } as IRequestSearchByFilter;
    });
  };

  const handleReset = () => {
    setCandidateData([]);
    setVacancyData([]);
    setRecruiterData([]);
    setState(initialState());
    setLabelSearchLogic("OR");
    setLabelData([]);
    setNeedClear(true);
  };

  useEffect(() => {
    if (!needClear) return;
    // handleSubmit();
    setNeedClear(false);
  }, [needClear]);

  useEffect(() => {
    handleSubmit();
  }, [
    recruiterData,
    vacancyData,
    candidateData,
    state,
    labelData,
    labelSearchLogic,
  ]);

  return (
    <>
      <div className="mb-8">
        <Multiselect
          addonBefore={<GlyphSearch />}
          className="mb-0"
          data={candidateAutocompleteData || []}
          indent={false}
          loading={candidateAutocompleteLoading}
          onChange={setCandidateData}
          onInputChange={changeAutocompleteCandidate}
          placeholder="Фамилия кандидата"
          shape="select"
          showArrow={false}
          value={candidateData}
        />
      </div>
      <div className="mb-8">
        <Multiselect
          addonBefore={<GlyphSearch />}
          className="mb-0"
          data={vacancyAutocompleteData || []}
          indent={false}
          loading={vacancyAutocompleteLoading}
          onChange={setVacancyData}
          onInputChange={setAutocompleteVacancy}
          placeholder="Название вакансии"
          shape="select"
          showArrow={false}
          value={vacancyData}
        />
      </div>
      <div className="mb-8">
        <Multiselect
          addonBefore={<GlyphSearch />}
          className="mb-0"
          data={recruiterAutocompleteData || []}
          indent={false}
          loading={recruiterAutocompleteLoading}
          onChange={setRecruiterData}
          onInputChange={setAutocompleteRecruiter}
          placeholder="Имя основного рекрутера"
          shape="select"
          showArrow={false}
          value={recruiterData}
        />
      </div>
      <div className="mb-8">
        <InputDatepicker
          classNameBackgroundColor={undefined}
          dates={state.datepickerValue}
          disabled={false}
          onBlurContainerField={undefined}
          onFocusContainerField={undefined}
          onSubmit={dates => handleChange("datepickerValue", dates)}
          placeholder={"Дата резюме"}
        />
      </div>
      <div className={cx(style.LabelSearchBlock, "mb-8")}>
        <Multiselect
          addonBefore={<GlyphSearch />}
          className="mb-0"
          data={labelAutocompleteData || []}
          indent={false}
          loading={labelAutocompleteLoading}
          onChange={setLabelData}
          onInputChange={setLabelAutocomplete}
          placeholder="Метки"
          shape="select"
          showArrow={false}
          value={labelData}
        />
        <RadioFormGroup
          defaultValue={"OR"}
          name={"labelSearchMode"}
          onChange={(e: any) => {
            setLabelSearchLogic(e.target.value as LabelSearchModel);
          }}
          shape={"horizontal"}
        >
          <Radio
            checked={labelSearchLogic === "OR"}
            label={"Или"}
            value={"OR"}
          />
          <Radio
            checked={labelSearchLogic === "AND"}
            label={"И"}
            value={"AND"}
          />
        </RadioFormGroup>
      </div>
      <div className="mb-8">
        <Checkbox
          checked={state.has_new_message}
          label="Есть новые сообщения"
          onChange={() =>
            handleChange("has_new_message", !state.has_new_message)
          }
        />
      </div>
      <Button onClick={handleReset} variant="secondary">
        Сбросить
      </Button>
    </>
  );
};
export default FilterSideBar;
