import { VacancyUserResponse } from "../../../types/vacation";
import { api, request } from "../common";
import {
  API_VACATION_LIST,
  API_VACATION_STATUSES,
  API_VACATION_CATEGORIES,
  API_VACATION_CARD,
  API_AUTOCOMPLETE_MANAGER,
  API_AUTOCOMPLETE_VACANCY,
  API_CREATE_CARD,
  API_VACANCY_GET_AVAILABLE_STATUSES,
  API_VACANCY_MOVE_STATUSES,
  API_V1,
  API_UPDATE_CARD,
  API_VACANCY_GET_FILE_INFO,
  API_VACANCY_HISTORY,
  API_VACANCY_DELETE_RESUME,
  API_VACANCY_SEND_RESUME,
  API_AUTOCOMPLETE_RECRUITER,
  API_VACANCY_BIND_LABEL,
  API_VACANCY_BIND_LABELS,
  API_VACANCY_UNBIND_LABELS,
  API_RECRUITER_ALL,
  API_RESEARCHER_ALL,
} from "../constants";
import { MultiselectData } from "@aurora/components";

export const vacationApi = api.injectEndpoints({
  endpoints: builder => ({
    addVacancyFile: builder.mutation<any, any>({
      query: data => ({
        data: data.document,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: `${API_V1}/vacancy/${data.vacancyId}/file/upload`,
        withCredentials: true,
      }),
    }),
    bindLabelToVacancy: builder.mutation<any, any>({
      query: params => {
        return {
          method: "POST",
          params: {
            labelId: params.labelId,
          },
          url: `${API_VACANCY_BIND_LABEL(params.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),
    bindLabelsToVacancy: builder.mutation<any, any>({
      query: data => {
        return {
          data: {
            labelIds: data.labelIds,
          },
          method: "POST",
          url: `${API_VACANCY_BIND_LABELS(data.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),
    createVacancy: builder.mutation<any, any>({
      query: data => ({
        data,
        method: "POST",
        url: `${API_CREATE_CARD}`,
        withCredentials: true,
      }),
    }),
    deleteVacancyFile: builder.mutation<any, any>({
      query: data => ({
        data: data.document,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: API_VACANCY_DELETE_RESUME(data.vacancyId),
        withCredentials: true,
      }),
    }),
    recruiterAll: builder.query<VacancyUserResponse, void>({
      query: () => ({
        method: "GET",
        url: `${API_RECRUITER_ALL}`,
        withCredentials: true,
      }),
    }),
    researcherAll: builder.query<VacancyUserResponse, void>({
      query: () => ({
        method: "GET",
        url: `${API_RESEARCHER_ALL}`,
        withCredentials: true,
      }),
    }),
    unbindLabelsToVacancy: builder.mutation<any, any>({
      query: data => {
        return {
          data: {
            labelIds: data.labelIds,
          },
          method: "POST",
          url: `${API_VACANCY_UNBIND_LABELS(data.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),
    updateVacancy: builder.mutation<any, any>({
      query: data => ({
        data,
        method: "PUT",
        url: `${API_UPDATE_CARD}/${data.id}`,
        withCredentials: true,
      }),
    }),
    vacanciesList: builder.query<any, any>({
      query: params => {
        return {
          method: "GET",
          params,
          url: `${API_VACATION_LIST}`,
          withCredentials: true,
        };
      },
    }),
    vacancyFile: builder.mutation<any, any>({
      query: data => ({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "GET",
        url: `${API_V1}/vacancy/${data.vacancyId}/file/download`,

        withCredentials: true,
      }),
    }),
    vacancyHistory: builder.query<any, any>({
      query: params => {
        return {
          method: "GET",
          url: `${API_VACANCY_HISTORY(params.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),
    // API_VACANCY_GET_AVAILABLE_STATUSES
    // API_VACANCY_MOVE_STATUSES
    vacationAutocompleteManager: builder.query<MultiselectData[], string>({
      query: query => {
        return {
          method: "GET",
          params: { query },
          url: `${API_AUTOCOMPLETE_MANAGER}`,
          withCredentials: true,
        };
      },
    }),

    vacationAutocompleteRecruiter: builder.query<MultiselectData[], string>({
      query: query => {
        return {
          method: "GET",
          params: { query },
          url: `${API_AUTOCOMPLETE_RECRUITER}`,
          withCredentials: true,
        };
      },
    }),
    vacationAutocompleteTitle: builder.query<MultiselectData[], string>({
      query: query => {
        return {
          method: "GET",
          params: { query },
          url: `${API_AUTOCOMPLETE_VACANCY}`,
          withCredentials: true,
        };
      },
    }),

    vacationCard: builder.mutation<any, any>({
      query: params => {
        return {
          method: "GET",
          params,
          url: `${API_VACATION_CARD}`,
          withCredentials: true,
        };
      },
    }),

    vacationCategories: builder.query<any, void>({
      query: () => {
        return {
          method: "GET",
          url: `${API_VACATION_CATEGORIES}`,
          withCredentials: true,
        };
      },
    }),

    vacationChangeStatus: builder.mutation<any, any>({
      query: params => {
        return {
          method: "POST",
          params,
          url: `${API_VACANCY_MOVE_STATUSES(params.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),

    vacationGetAvailableStatuses: builder.mutation<any, any>({
      query: query => {
        return {
          method: "GET",
          url: `${API_VACANCY_GET_AVAILABLE_STATUSES(query.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),
    vacationGetFileInfo: builder.mutation<any, any>({
      query: query => {
        return {
          method: "GET",
          url: `${API_VACANCY_GET_FILE_INFO(query.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),
    vacationSendResume: builder.mutation<any, any>({
      query: data => {
        return {
          method: "POST",
          params: { personIds: data.personIds },
          url: `${API_VACANCY_SEND_RESUME(data.vacancyId)}`,
          withCredentials: true,
        };
      },
    }),
    vacationStatuses: builder.query<any, void>({
      query: () => {
        return {
          method: "GET",
          url: `${API_VACATION_STATUSES}`,
          withCredentials: true,
        };
      },
    }),
  }),
});

export const vacationCustomApi = {
  downloadFile: (data: any) => {
    return request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "GET",
      responseType: "blob",
      url: `${API_V1}/vacancy/${data.vacancyId}/file/download`,
    });
  },
  generateReportVacancy: (data: any) => {
    return request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      params: { ...data },
      responseType: "blob",
      url: `${API_V1}/report/vacancy`,
    });
  },
  generateReportVacancyCategory: (data: any) => {
    return request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      params: { ...data },
      responseType: "blob",
      url: `${API_V1}/report/vacancyCategory`,
    });
  },
};

export const {
  useAddVacancyFileMutation,
  useBindLabelsToVacancyMutation,
  useCreateVacancyMutation,
  useDeleteVacancyFileMutation,
  useRecruiterAllQuery,
  useResearcherAllQuery,
  useUnbindLabelsToVacancyMutation,
  useUpdateVacancyMutation,
  useVacanciesListQuery,
  useVacancyFileMutation,
  useVacancyHistoryQuery,
  useVacationAutocompleteManagerQuery,
  useVacationAutocompleteRecruiterQuery,
  useVacationAutocompleteTitleQuery,
  useVacationCardMutation,
  useVacationCategoriesQuery,
  useVacationChangeStatusMutation,
  useVacationGetAvailableStatusesMutation,
  useVacationGetFileInfoMutation,
  useVacationSendResumeMutation,
  useVacationStatusesQuery,
} = vacationApi;
