// Аавторизация
import * as process from "process";

export const API_AUTH_HOST = process.env.REACT_APP_CLIENT_URL
  ? process.env.REACT_APP_CLIENT_URL
  : "http://localhost:3030/api";

export const API_AUTH_LOGIN = API_AUTH_HOST + "/login";
export const API_AUTH_LOGOUT = API_AUTH_HOST + "/logout";
export const API_AUTH_REFRESH = API_AUTH_HOST + "/get-token";

// Основное API v1
export const API_V1_HOST = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "https://fh.dv.fork-tech.ru:8080";
export const API_V1 = API_V1_HOST + "/api/v1";

// Vacation
// export const API_VACATION_LIST = API_V1 + "/vacancy/statuses";
export const API_VACATION_CARD = API_AUTH_HOST + "/vacancy/card";
export const API_VACATION_LIST = API_AUTH_HOST + "/vacancy/search/by_filter";
export const API_VACATION_STATUSES =
  API_AUTH_HOST + "/status/flow/default/VACANCY";
// status/flow/default/vacancy
export const API_VACANCY_DELETE_RESUME = (id: string) =>
  `${API_AUTH_HOST}/vacancy/${id}/file/delete`;
export const API_VACANCY_GET_FILE_INFO = (id: string) =>
  `${API_AUTH_HOST}/vacancy/${id}/file/info`;

export const API_VACATION_CATEGORIES = API_AUTH_HOST + "/vacancy/category/all";
export const API_CREATE_CARD = API_AUTH_HOST + "/vacancy";
export const API_UPDATE_CARD = API_AUTH_HOST + "/vacancy";

export const API_VACANCY_GET_AVAILABLE_STATUSES = (vacancyId: string) =>
  `${API_AUTH_HOST}/vacancy/${vacancyId}/state/transition/available`;
export const API_VACANCY_MOVE_STATUSES = (vacancyId: string) =>
  `${API_AUTH_HOST}/vacancy/${vacancyId}/state/transition/move`;

export const API_VACANCY_SEND_RESUME = (vacancyId: string) =>
  `${API_AUTH_HOST}/vacancy/${vacancyId}/resume/send`;

export const API_VACANCY_HISTORY = (vacancyId: string) =>
  `${API_AUTH_HOST}/vacancy/${vacancyId}/state/history`;

export const API_UPLOAD_FILE_VACANCY = API_V1 + "/vacancy/file/upload";

export const API_AUTOCOMPLETE_MANAGER =
  API_AUTH_HOST + "/vacancy/autocomplete/by_manager";
export const API_AUTOCOMPLETE_VACANCY =
  API_AUTH_HOST + "/vacancy/autocomplete/by_title";
// /api/v1/VACANCY/{personId}/label/...
export const API_VACANCY_BIND_LABEL = (vacancyId: string) =>
  `${API_AUTH_HOST}/vacancy/${vacancyId}/label/add/by_id`;
export const API_VACANCY_BIND_LABELS = (vacancyId: string) =>
  `${API_AUTH_HOST}/vacancy/${vacancyId}/label/add/by_ids`;
export const API_VACANCY_UNBIND_LABELS = (vacancyId: string) =>
  `${API_AUTH_HOST}/vacancy/${vacancyId}/label/remove/by_ids`;
export const API_AUTOCOMPLETE_RECRUITER =
  API_AUTH_HOST + "/vacancy/autocomplete/by_recruiter";
export const API_RESEARCHER_ALL = API_AUTH_HOST + "/vacancy/researcher/all";
export const API_RECRUITER_ALL = API_AUTH_HOST + "/vacancy/recruiter/all";

// Candidate
export const API_PERSON = `${API_AUTH_HOST}/person`;
export const API_CANDIDATE_LIST = API_AUTH_HOST + "/candidate/list";
export const API_CANDIDATE_AUTOCOMPLETE =
  API_AUTH_HOST + "/candidate/autocomplete";
export const API_CANDIDATE_DATA = (id: string) =>
  `${API_AUTH_HOST}/candidate/${id}`;
export const API_CANDIDATE = (id: string) => `${API_PERSON}/${id}`;
export const API_CANDIDATE_DOWNLOAD_RESUME = (id: string) =>
  `${API_V1}/person/${id}/file/download`;
export const API_CANDIDATE_INFO_RESUME = (id: string) =>
  `${API_V1}/person/${id}/file/info`;
export const API_CANDIDATE_DELETE_RESUME = (id: string) =>
  `${API_V1}/person/${id}/file/delete`;

export const API_CANDIDATES_GET_AVAILABLE_STATUSES = (
  personId: string,
  vacancyId: string,
) => `${API_PERSON}/${personId}/${vacancyId}/state/transition/available`;
export const API_PERSON_STATE_AVAILABLE = `${API_PERSON}/state/transition/available`;
export const API_CANDIDATE_STATUS_HISTORY = (
  personId: string,
  vacancyId: string,
) => `${API_PERSON}/${personId}/${vacancyId}/state/history`;
export const API_CANDIDATE_MOVE_STATUSES = (
  personId: string,
  vacancyId: string,
) => `${API_PERSON}/${personId}/${vacancyId}/state/transition/move`;
export const API_MASS_CANDIDATE_MOVE_STATUSES = `${API_PERSON}/state/transition/move`;
export const API_CANDIDATE_BIND_TO_VACANCY = (personId: string) =>
  `${API_PERSON}/${personId}/bind_to_vacancy`;
export const API_CANDIDATE_UNBIND_FROM_VACANCY = (personId: string) =>
  `${API_PERSON}/${personId}/unbind_from_vacancy`;
// /api/v1/person/{personId}/label/add/by_id
export const API_CANDIDATE_BIND_LABEL = (personId: string) =>
  `${API_PERSON}/${personId}/label/add/by_id`;
export const API_CANDIDATE_BIND_LABELS = (personId: string) =>
  `${API_PERSON}/${personId}/label/add/by_ids`;
export const API_CANDIDATE_UNBIND_LABELS = (personId: string) =>
  `${API_PERSON}/${personId}/label/remove/by_ids`;
export const API_CANDIDATE_PARSE = `${API_V1}/person/file/parse`;
export const API_CANDIDATE_STATUS_LIST_ALL = `${API_AUTH_HOST}/status/flow/default/CANDIDATE`;
export const API_CANDIDATE_FIND_BY_PHONE = `${API_PERSON}/find/by_phone`;
export const API_CANDIDATE_FIND_BY_EMAIL = `${API_PERSON}/find/by_email`;
// status/flow/default/{type}
// OTHER
export const API_CITIES_DICTIONARY = API_AUTH_HOST + "/dictionary/city/all";
export const API_MANAGERS_DICTIONARY = API_AUTH_HOST + "/vacancy/manager/all";
export const API_LABELS_BY_PART_TEXT = `${API_AUTH_HOST}/dictionary/label/find/by_pvalue`;
export const API_LABEL_ADD = `${API_AUTH_HOST}/dictionary/label`;
// CHAT
export const API_CREATE_CHAT = API_AUTH_HOST + "/chat";
export const API_SEND_MESSAGE = API_AUTH_HOST + "/chat/message/send";
export const API_CHAT_HAS_ANY_NEW_MESSAGES =
  API_AUTH_HOST + "/chat/participant/find/by_new_message";

export const API_GET_EXTERNAL_CHAT_LIST =
  API_AUTH_HOST + "/chat/find/by_participant_external";
export const API_GET_ALL_MESSAGES = (chatId: string) =>
  `${API_AUTH_HOST}/chat/${chatId}/message/all`;

// USER
export const USER_URL = API_AUTH_HOST + "/user";
export const GET_USER_AVAILABLE = USER_URL + "/availability";
export const GET_USER_PERMISSIONS = USER_URL + "/permission/all";
export const GET_USER_CATEGORIES = USER_URL + "/category/all";
