import { CircleLoader } from "@aurora/components";
import Content from "components/AuthComponents/Content/Content";
import Auth from "components/NotAuthComponents/Auth";
import { ROUTE_AUTH } from "constants/routes";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const ContainerRouter = () => {
  const isAuth = useSelector((store: any) => store.authSlice.isAuth);
  const isLoading = useSelector((store: any) => store.authSlice.isLoading);

  useEffect(() => {
    const handleTabKey = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        const focusableElements = Array.from(
          document.querySelectorAll("input, textarea, select, button, a[href]"),
        ) as HTMLElement[];

        const currentIndex = focusableElements.indexOf(
          document.activeElement as HTMLElement,
        );

        if (e.shiftKey) {
          // Переход на предыдущий элемент при Shift+Tab
          focusableElements[currentIndex - 1]?.focus();
        } else {
          // Переход на следующий элемент при Tab
          focusableElements[currentIndex + 1]?.focus();
        }

        e.preventDefault(); // Предотвращаем стандартное поведение Tab
      }
    };

    // Добавляем обработчик событий при монтировании компонента
    document.addEventListener("keydown", handleTabKey);

    // Убираем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener("keydown", handleTabKey);
    };
  }, []);

  if (isLoading) {
    return <CircleLoader brandColor type={"page"} />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<CircleLoader brandColor type={"page"} />}>
        <Routes>
          {isAuth ? (
            // Авторизованная зона
            <>
              <Route element={<Content />} path="*" />
            </>
          ) : (
            // Неавторизованная зона
            <>
              <Route element={<Navigate to={ROUTE_AUTH} />} path="/" />
              <Route element={<Auth />} path={ROUTE_AUTH} />
              <Route element={<Auth />} path="*" />
            </>
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default ContainerRouter;
