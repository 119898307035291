import {
  useCandidateCreateMutation,
  useCandidateParseFileMutation,
  useFindByEmailMutation,
  useFindByPhoneMutation,
  usePersonBindToVacancyMutation,
  useUploadResumeMutation,
} from "../../../../core/api/candidate/candidate";
import { DocIcon } from "../../../../services/docicon.service";
import { IConflictData } from "../../../../types/services/snackbar/types";
import SkeletonField from "../../../AuthComponents/VacationCard/Skeleton/SkeletonField";
import style from "./Modal.module.scss";
import {
  Button,
  Popup,
  Input,
  FileUploader,
  Text,
  useSnackbars,
} from "@aurora/components";
import { GlyphBin, GlyphCheck, GlyphClose, GlyphEdit } from "@aurora/icons";
import cx from "classnames";
import { DICTIONARY } from "constants/dictionary";
import React, { FC, useEffect, useState } from "react";
import {
  checkEmail,
  checkPhone,
  isValidUsername,
  validateName,
} from "services/check.service";
import { snackbarSuccess, snackbarFail } from "services/snackbar.service";

const CreateCandidateModal: FC<IVacanciesContext> = ({
  clickOnClose,
  onSuccessModal,
  vacancy,
}) => {
  const { snackbarInfo } = useSnackbars();
  const [isLoading, changeStatusLoading] = useState(false);
  const [files, changeFileUploader]: any = useState([]);
  const [contactsData, changeContactData]: any = useState([]);
  const [disableSaveForm, changeDisableSaveForm] = useState(true);
  const [fileLimit, setFileLimit] = useState(50);
  const [isCheckPerson, changeCheckPerson] = useState(false);
  const [notifyErrors, setNotifyErrors] = useState<IConflictData[]>([]);
  const [parsingResume] = useCandidateParseFileMutation();
  const [checkPersonByPhone] = useFindByPhoneMutation();
  const [checkPersonByEmail] = useFindByEmailMutation();
  const [bindPersonToVacancy] = usePersonBindToVacancyMutation();
  const [createPerson] = useCandidateCreateMutation();
  const [uploadFileResume] = useUploadResumeMutation();

  useEffect(() => {
    if (notifyErrors.length > 0) {
      notifyErrors.map(error => {
        const fail: any = snackbarFail(
          DICTIONARY.CONFLICT_PROPERTY_PATH(error.propertyPath, error.message),
        );
        snackbarInfo?.show(fail);
      });
    }
  }, [notifyErrors]);
  useEffect(() => {
    if (files.length && !isLoading) {
      changeStatusLoading(true);
      Promise.all([
        ...files.map((file: Blob) => {
          const formData = new FormData();
          formData.append("document", file);
          return parsingResume({
            document: formData,
          });
        }),
      ])
        .then((results: any[]) => {
          changeContactData([
            ...results.map((result: any, idx: number) => {
              return {
                email: result.data?.email || "",
                firstName: result.data?.firstName || "",
                fullName: result.data?.fullName || "",
                isValidEmail: checkEmail(result.data?.email),
                isValidPhone: checkPhone(result.data?.phone),
                isValidTelegramName: isValidUsername(result.data?.telegramName),
                lastName: result.data?.lastName || "",
                middleName: result.data?.middleName || "",
                notValid:
                  result.error && result.error.status === 400
                    ? "Файл не удалось распознать"
                    : null,
                phone: result.data?.phone || "",
                resume: files[idx],
              };
            }),
          ]);
        })
        .then(() => {
          changeCheckPerson(true);
          setFileLimit(files.length);
        });
    }
  }, [files]);
  useEffect(() => {
    if (contactsData.length > 0 && isCheckPerson) {
      const newContactsData = contactsData;

      Promise.all(
        contactsData.map((person: any) => {
          return handleFetchError(person);
        }),
      )
        .then(result => {
          result.map((person: any, idx: number) => {
            if (person && person.data && person.data.id) {
              newContactsData[idx] = {
                ...newContactsData[idx],
                id: person.data.id,
                linkToVacancy: !!vacancy,
                needLinkToVacancy: null,
                notValid: `Кандидат ${person.data.fullName} уже есть в базе.${
                  vacancy ? " Подтянуть карточку к данной вакансии?" : ""
                }`,
              };
            }
          });
          // сортирвка массива с данными
          //todo:  починить - не работает, если изменится порядок индексов в массиве после сортировки, он перестанет соотвествовать индексам в массиве files
          // newContactsData.sort((a: any, b: any) => {
          //   console.log("Sorting");
          //   if (a.linkToVacancy && !b.linkToVacancy) return 1;
          //   if (a.notValid && !b.linkToVacancy) return 1;
          //   return 0;
          // });
          changeContactData(newContactsData);
        })
        .finally(() => {
          changeCheckPerson(false);
          changeStatusLoading(false);
          showSnackbarSuccess();
        });
    }
  }, [isCheckPerson]);

  async function fetchData(personPhoneOrEmail: any): Promise<any> {
    if (personPhoneOrEmail.phone) {
      return checkPersonByPhone({ phone: personPhoneOrEmail.phone }).then(
        (result: any) => {
          if (result.error?.status === 404) {
            return Promise.reject(result);
          }
          return result;
        },
      );
    }
    if (personPhoneOrEmail.email) {
      return checkPersonByEmail({ email: personPhoneOrEmail.email }).then(
        (result: any) => {
          if (result.error?.status === 404) {
            //todo: есть нюанс вызова метода дважды, когда у резюме нет номера телефона и первый запрос на проверку по нему не идет
            return Promise.reject({ ...result, checked: true });
          }
          return result;
        },
      );
    }
    return Promise.reject();
  }

  async function handleFetchError(
    personPhoneOrEmail: any,
  ): Promise<any | null> {
    return await fetchData(personPhoneOrEmail).catch((error: any) => {
      if (
        error?.error &&
        error?.error.status === 404 &&
        personPhoneOrEmail.email != "" &&
        !personPhoneOrEmail.checked
      ) {
        const newPersonPhoneOrEmail = {
          checked: true,
          email: personPhoneOrEmail.email,
        };
        return handleFetchError(newPersonPhoneOrEmail);
      }
      return null;
    });
  }

  function showSnackbarSuccess() {
    snackbarInfo?.show(snackbarSuccess(DICTIONARY.SUCCESS_RESUME));
  }

  useEffect(() => {
    if (contactsData.length > 0) {
      let disabled = false;
      contactsData.map((element: any) => {
        if (!element.notValid && !disabled) {
          disabled =
            !element.firstName ||
            !element.lastName ||
            (!element.phone && !element.email);

          if (element.email && !element.isValidEmail) disabled = true;
          if (element.phone && !element.isValidPhone) disabled = true;
          if (element.telegramName && !element.isValidTelegramName)
            disabled = true;
        }
        if (element.notValid && element.linkToVacancy && !disabled) {
          disabled = element.needLinkToVacancy === null;
        }
      });
      changeDisableSaveForm(disabled);
    }
  }, [contactsData, isCheckPerson]);

  function createPersons() {
    const newNotifyErrors: IConflictData[] = [];
    Promise.all([
      ...contactsData.map((person: any) => {
        if (!person.notValid) {
          // запрос на создание персоны если не было каких либо предупреждений
          return createPerson({
            email: person.email,
            firstName: person.firstName,
            lastName: person.lastName,
            middleName: person.middleName,
            phone: person.phone,
            telegramName: person.telegramName,
          });
        }
      }),
    ])
      .then((result: any) => {
        if (result) {
          Promise.all([
            ...contactsData.map((person: any, idx: number) => {
              if (!person.notValid && !result[idx]?.error) {
                const formData = new FormData();
                formData.append("document", person.resume);
                return uploadFileResume({
                  document: formData,
                  personId: result[idx].data.id,
                });
              } else if (result[idx]?.error) {
                newNotifyErrors.push(...result[idx].error.data.errors);
              }
            }),
          ])
            .then(() => {
              if (vacancy) {
                Promise.all([
                  ...contactsData.map((person: any, idx: number) => {
                    if (!person.notValid) {
                      // привязка только что созданных персон
                      return bindPersonToVacancy({
                        personId: result[idx].data.id,
                        vacancyId: vacancy,
                      });
                    }
                    if (person.notValid && person.needLinkToVacancy) {
                      // привязка персон существующих
                      return bindPersonToVacancy({
                        personId: person.id,
                        vacancyId: vacancy,
                      });
                    }
                  }),
                ]).catch(e => {
                  console.log("Error bind to vacancy", e);
                });
              }
            })
            .catch(e => {
              console.log("Error upload resume", e);
            });
        }
      })
      .catch(e => {
        console.log("Error create person", e);
      })
      .finally(() => {
        setNotifyErrors(newNotifyErrors);
        setTimeout(() => {
          onSuccessModal();
          clickOnClose(true);
        }, 1500);
      });
  }

  function openResume(idx: number) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(contactsData[idx].resume);
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
  }

  return (
    <Popup
      classNameContent={cx(style.modalContent, "p-16")}
      classNameWrapper={cx("p-0")}
      onClickCloseCross={clickOnClose}
      onClickOutside={clickOnClose}
    >
      <div className={style.modalContainer}>
        {isLoading ? (
          <SkeletonField />
        ) : (
          <>
            <div className={"title-and-buttons-align"}>
              <h1 className={cx("my-0 text-black")}>Загрузить резюме</h1>
              <div>
                <Button onClick={clickOnClose} variant="secondary">
                  Отмена
                </Button>
                <Button
                  disabled={disableSaveForm}
                  onClick={() => {
                    changeStatusLoading(true);
                    createPersons();
                  }}
                  variant="primary"
                >
                  Сохранить
                </Button>
              </div>
            </div>
            <FileUploader
              accept={".rtf,.pdf,.doc,.docx"}
              className={cx(
                "my-8",
                contactsData.length > 0 ? style.hidden : "",
              )}
              files={files}
              filesLimit={fileLimit}
              listShape="cardCell"
              mode="attach"
              multiple={true}
              onChange={(files: any) => {
                changeFileUploader(files);
              }}
              validateFiles={async files => {
                const ONE_MB = 1000000;
                const filteredFiles = files.filter(
                  f =>
                    (f.name.includes(".pdf") ||
                      f.name.includes(".doc") ||
                      f.name.includes(".docx") ||
                      f.name.includes(".rtf")) &&
                    f.size <= ONE_MB * 5,
                );

                if (!filteredFiles.length) {
                  alert(
                    "Файл может иметь расширение (rtf, pdf, doc, docx) и не должен превышать 5МБ",
                  );
                }

                return filteredFiles;
              }}
              variant="icon"
            />

            {contactsData.length ? (
              <table className="hr-table-style-not-fixed mt-12">
                <colgroup>
                  <col width="6%" />
                  <col width="15.5%" />
                  <col width="13.5%" />
                  <col width="15%" />
                  <col width="18.5%" />
                  <col width="19.5%" />
                  <col width="12%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Файл</th>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Отчество</th>
                    <th>Телефон</th>
                    <th>Электронная почта</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {contactsData.map((line: any, idx: number) => {
                    const classNameTr = line.isEditable
                      ? cx(style.modalTableLine, style.modalTableLineEditable)
                      : style.modalTableLine;
                    return (
                      <tr key={idx} className={classNameTr}>
                        {!line.notValid ? (
                          <>
                            <td className={"truncate-cell"}>
                              <a
                                className="link"
                                href="#"
                                onClick={() => {
                                  openResume(idx);
                                }}
                              >
                                <DocIcon fileName={line.resume?.name} />
                              </a>
                            </td>
                            <td
                              className={"truncate-cell"}
                              title={line.lastName}
                            >
                              {line.lastName}
                            </td>
                            <td
                              className={"truncate-cell"}
                              title={line.firstName}
                            >
                              {line.firstName}
                            </td>
                            <td
                              className={"truncate-cell"}
                              title={line.middleName}
                            >
                              {line.middleName}
                            </td>
                            <td
                              className={cx(
                                "truncate-cell",
                                line.isValidPhone ? null : "text-critical-90",
                              )}
                              title={
                                line.isValidPhone
                                  ? line.phone
                                  : DICTIONARY.NOT_VALID_PHONE
                              }
                            >
                              {line.phone}
                            </td>
                            <td
                              className={cx(
                                "truncate-cell",
                                line.isValidEmail ? null : "text-critical-90",
                              )}
                              title={
                                line.isValidEmail
                                  ? line.email
                                  : DICTIONARY.NOT_VALID_EMAIL
                              }
                            >
                              {line.email}
                            </td>
                            <td className={"text-right truncate-cell"}>
                              <Button
                                className={"mr-4"}
                                iconLeft={
                                  line.isEditable ? (
                                    <GlyphCheck className="zoom-2" />
                                  ) : (
                                    <GlyphEdit />
                                  )
                                }
                                onClick={() => {
                                  const newValues = [...contactsData];
                                  newValues[idx].isEditable =
                                    !newValues[idx].isEditable;
                                  changeContactData(newValues);
                                }}
                                size="s"
                                variant={
                                  line.isEditable ? "primary" : "secondary"
                                }
                              ></Button>

                              <Button
                                className="text-critical-base"
                                iconLeft={<GlyphBin />}
                                onClick={() => {
                                  const newValues = [...contactsData];
                                  newValues.splice(idx, 1);
                                  changeContactData(newValues);
                                  const newFiles = [...files];
                                  newFiles.splice(idx, 1);
                                  changeFileUploader(newFiles);
                                  changeDisableSaveForm(newValues.length === 0);
                                }}
                                size="s"
                                variant="secondary"
                              ></Button>
                            </td>

                            {line.isEditable ? (
                              <td className={cx(style.modalTableEdit, "py-2")}>
                                <div
                                  className={style.modalTableLineEditContainer}
                                >
                                  <div
                                    className={
                                      style.modalTableLineEditContainerMainInfo
                                    }
                                  >
                                    <Input
                                      label={
                                        <span>
                                          Фамилия{" "}
                                          <span
                                            className={"text-critical-base"}
                                          >
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e: any) => {
                                        const newValues = [...contactsData];
                                        newValues[idx].lastName = validateName(
                                          e.target.value,
                                        );
                                        changeContactData(newValues);
                                      }}
                                      placeholder="Введите"
                                      value={line.lastName}
                                    />
                                  </div>
                                  <div
                                    className={
                                      style.modalTableLineEditContainerMainInfo
                                    }
                                  >
                                    <Input
                                      label={
                                        <span>
                                          Имя{" "}
                                          <span
                                            className={"text-critical-base"}
                                          >
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e: any) => {
                                        const newValues = [...contactsData];
                                        newValues[idx].firstName = validateName(
                                          e.target.value,
                                        );
                                        changeContactData(newValues);
                                      }}
                                      placeholder="Введите"
                                      value={line.firstName}
                                    />
                                  </div>
                                  <div
                                    className={
                                      style.modalTableLineEditContainerMainInfo
                                    }
                                  >
                                    <Input
                                      label="Отчество"
                                      onChange={(e: any) => {
                                        const newValues = [...contactsData];
                                        newValues[idx].middleName =
                                          validateName(e.target.value);
                                        changeContactData(newValues);
                                      }}
                                      placeholder="Введите"
                                      value={line.middleName}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={cx(
                                    style.modalTableLineEditContainer,
                                    "mt-4",
                                  )}
                                >
                                  <div
                                    className={
                                      style.modalTableLineEditContainerSecondInfo
                                    }
                                  >
                                    <Input
                                      description={
                                        line.isValidPhone
                                          ? null
                                          : DICTIONARY.NOT_VALID_PHONE
                                      }
                                      label={
                                        <span>
                                          Телефон{" "}
                                          <span
                                            className={"text-critical-base"}
                                          >
                                            *
                                          </span>
                                        </span>
                                      }
                                      mask={"9 (999) 999-99-99"}
                                      onChange={(e: any) => {
                                        const newValues = [...contactsData];
                                        newValues[idx].phone = e.target.value;
                                        newValues[idx].isValidPhone =
                                          checkPhone(newValues[idx].phone);
                                        changeContactData(newValues);
                                      }}
                                      placeholder="Введите"
                                      status={
                                        line.isValidPhone ? null : "error"
                                      }
                                      value={line.phone}
                                    />
                                  </div>
                                  <div
                                    className={
                                      style.modalTableLineEditContainerSecondInfo
                                    }
                                  >
                                    <Input
                                      description={
                                        line.isValidEmail
                                          ? null
                                          : DICTIONARY.NOT_VALID_EMAIL
                                      }
                                      label={
                                        <span>
                                          E-mail{" "}
                                          <span
                                            className={"text-critical-base"}
                                          >
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e: any) => {
                                        const newValues = [...contactsData];
                                        newValues[idx].email =
                                          e.target.value || null;
                                        newValues[idx].isValidEmail =
                                          checkEmail(newValues[idx].email);
                                        changeContactData(newValues);
                                      }}
                                      placeholder="Введите"
                                      status={
                                        line.isValidEmail ? null : "error"
                                      }
                                      value={line.email}
                                    />
                                  </div>
                                  <div
                                    className={
                                      style.modalTableLineEditContainerSecondInfo
                                    }
                                  >
                                    <Input
                                      description={
                                        line.isValidTelegramName
                                          ? null
                                          : DICTIONARY.NOT_VALID_TELEGRAM
                                      }
                                      label={<span>Ник в Telegram </span>}
                                      onChange={(e: any) => {
                                        const newValues = [...contactsData];
                                        newValues[idx].telegramName =
                                          e.target.value || null;
                                        newValues[idx].isValidTelegramName =
                                          newValues[idx].telegramName.length ===
                                            0 ||
                                          isValidUsername(
                                            newValues[idx].telegramName,
                                          );
                                        changeContactData(newValues);
                                      }}
                                      placeholder="Введите"
                                      status={
                                        line.isValidTelegramName
                                          ? null
                                          : "error"
                                      }
                                      value={line.telegramName}
                                    />
                                  </div>
                                </div>
                              </td>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <td className={"truncate-cell"}>
                              <a
                                className="link"
                                href="#"
                                onClick={() => {
                                  openResume(idx);
                                }}
                              >
                                <DocIcon fileName={line.resume?.name} />
                              </a>
                            </td>
                            <td colSpan={5}>
                              <Text
                                color={
                                  line.linkToVacancy
                                    ? "colorAttention"
                                    : "colorDestructive"
                                }
                                indent={true}
                                tag={"div"}
                                type={"lineRegular"}
                              >
                                {line.notValid}
                              </Text>
                              <Text
                                color={"colorForeground2"}
                                indent={true}
                                tag={"div"}
                                type={"lineRegular"}
                              >
                                {line.resume.name}
                              </Text>
                            </td>
                            {line.linkToVacancy && (
                              <td className={style.TextAlignRight}>
                                <Button
                                  className={
                                    line.needLinkToVacancy === true
                                      ? "text-white"
                                      : "text-constructive"
                                  }
                                  disabled={false}
                                  iconLeft={<GlyphCheck />}
                                  onClick={() => {
                                    const newValues = [...contactsData];
                                    newValues[idx].needLinkToVacancy = true;
                                    changeContactData(newValues);
                                  }}
                                  size="s"
                                  variant={
                                    line.needLinkToVacancy === true
                                      ? "buy"
                                      : "secondary"
                                  }
                                ></Button>
                                <Button
                                  className={cx(
                                    line.needLinkToVacancy === false
                                      ? "text-white"
                                      : "text-destructive",
                                    "ml-4",
                                  )}
                                  disabled={false}
                                  iconLeft={<GlyphClose />}
                                  onClick={() => {
                                    const newValues = [...contactsData];
                                    newValues[idx].needLinkToVacancy = false;
                                    changeContactData(newValues);
                                  }}
                                  size="s"
                                  variant={
                                    line.needLinkToVacancy === false
                                      ? "sell"
                                      : "secondary"
                                  }
                                ></Button>
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
          </>
        )}
      </div>
    </Popup>
  );
};
export default CreateCandidateModal;
