import { useLazyCandidatesListQuery } from "../../../core/api/candidate/candidate";
import MassEffectModal from "../../BaseComponents/Popups/MassEfffect/Modal";
import CandidatesList from "./Candidates/List/CandidatesList";
import History from "./History/History";
import VacationCardInfo from "./Info/VacationCardInfo";
import SkeletonField from "./Skeleton/SkeletonField";
import style from "./VacancionCard.module.scss";
import { Badge, Headline, Pagination, Tab, Tabs } from "@aurora/components";
import {
  GlyphBetweenAccounts,
  GlyphEdit,
  GlyphMore,
  GlyphPlus,
  GlyphSurvey,
  GlyphSwap,
} from "@aurora/icons";
import cx from "classnames";
import AdditionallyMenu from "components/BaseComponents/AdditionallyMenu";
import CreateCandidateModal from "components/BaseComponents/Popups/CreateCandidate/Modal";
import LinkWithVacancy from "components/BaseComponents/Popups/LinkWithVacancy/Modal";
import VacancyBadge from "components/BaseComponents/VacancyBadge";
import {
  ROUTE_CREATE_CANDIDATE,
  ROUTE_VACANCIES_UPDATE,
} from "constants/routes";
import { useVacationCardMutation } from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { generateRandomIdByLength } from "services/generate.service";

interface Props {
  vacancy?: any;
  openUpdateStatus?: any;
}

function getInitQueryParams(vacancyId: string) {
  return {
    createPeriodEnd: "",
    createPeriodStart: "",
    pageNum: 1,
    pageSize: 10,
    personIds: "",
    vacancyIds: vacancyId,
  };
}

const VacationCard: FC<Props> = ({ openUpdateStatus, vacancy }: any) => {
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );

  const [isShowVacancyPopup, setShowVacancyPopup] = useState(false);
  const [isShowMassEffectPopup, setShowMassEffect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [activeTab, changeActiveTab] = useState(0);
  const [isVacationCardLoading, changeVacationDataLoading] = useState(true);
  const [vacationCardData, changeVacationCardData]: any = useState(null);
  const [isOpenAddCandidateModal, changeIsOpenAddCandidateModal]: any =
    useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [candidatesData, setCandidatesData]: any = useState(null);
  const [isCandidateDataLoading, setCandidateDataLoading] = useState(true);

  const [queryParams, changeQueryParams] = useState({
    vacancyId: vacancy.id,
  });
  const [queryParamsCandidate, updateQueryParamsCandidate] = useState<any>(
    getInitQueryParams(vacancy.id),
  );

  const [loadDataCard] = useVacationCardMutation();
  const [loadCandidatesData] = useLazyCandidatesListQuery();

  useEffect(() => {
    setSearchParams({ vacancyId: vacancy.id });
  }, []);

  useEffect(() => {
    if (activeTab === 0) {
      updateVacancyCardData();
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 1) {
      // updateQueryParamsCandidate(queryParamsCandidate);
      updateCandidateData();
    }
  }, [activeTab, queryParamsCandidate]);

  function updateCandidateData(sort?: any) {
    setCandidateDataLoading(true);

    return loadCandidatesData(queryParamsCandidate).then((result: any) => {
      setCandidatesData(result.data);
      setPageCount(
        result.data
          ? Math.ceil(result.data.total / queryParamsCandidate.pageSize)
          : 0,
      );
      updateVacancyCardData();
      setCandidateDataLoading(false);
      return result.data;
    });
  }
  function updateVacancyCardData() {
    return loadDataCard(queryParams).then((result: any) => {
      changeVacationCardData(result.data);
      changeVacationDataLoading(false);
      return result.data;
    });
  }

  return (
    <>
      {isVacationCardLoading ? (
        <SkeletonField count={8} />
      ) : (
        <div className={style.VacationCard}>
          <div className={style.VacationCardHeader}>
            <div>
              <VacancyBadge badge={vacationCardData}></VacancyBadge>
              <Headline
                addonContent={
                  vacationCardData.positionTotalCount > 0 && (
                    <Badge
                      color={"alert"}
                      label={
                        vacationCardData.positionClosedCount +
                        "/" +
                        vacationCardData.positionTotalCount
                      }
                      shape={"pill"}
                      size={"l32"}
                      variant={"primary"}
                    />
                  )
                }
                positionAddonContent={"right"}
                title={vacationCardData.title}
              />
            </div>
            {isManagerOrWatcher ? (
              ""
            ) : (
              <div className={style.VacationCardHeaderButtons}>
                <AdditionallyMenu
                  btn={{
                    className: cx(style.CreateButton, "pr-12"),
                    icon: <GlyphPlus />,
                    name: "Добавить кандидата",
                    shape: "circle",
                    variant: "primary",
                  }}
                  list={[
                    {
                      icon: <GlyphBetweenAccounts />,
                      name: "Загрузить резюме",
                      onClick: () => {
                        changeIsOpenAddCandidateModal(true);
                      },
                    },
                    {
                      icon: <GlyphSurvey />,
                      name: "Добавить из списка",
                      onClick: () => {
                        setShowVacancyPopup(true);
                      },
                    },
                    {
                      icon: <GlyphPlus />,
                      name: "Создать кандидата",
                      onClick: () => {
                        navigate(
                          `${ROUTE_CREATE_CANDIDATE}?vacancyId=${vacancy.id}`,
                        );
                      },
                    },
                  ]}
                />
                <AdditionallyMenu
                  btn={{
                    className: cx(style.CreateButton, "text-black"),
                    icon: <GlyphMore />,
                    variant: "secondary",
                  }}
                  list={[
                    {
                      icon: <GlyphEdit />,
                      name: "Редактировать",
                      onClick: () => {
                        navigate(
                          `${ROUTE_VACANCIES_UPDATE}/${vacationCardData.id}`,
                        );
                      },
                    },
                    {
                      icon: <GlyphSwap />,
                      name: "Перевод статуса",
                      onClick: () => {
                        openUpdateStatus(() => updateVacancyCardData);
                      },
                    },
                    // FORK-698: Front. Убрать кнопку Массовые действия
                    // {
                    //   icon: <GlyphPeople />,
                    //   name: "Массовое действие",
                    //   onClick: () => {
                    //     setShowMassEffect(true);
                    //   },
                    // },
                  ]}
                />
              </div>
            )}
          </div>

          <div className="mb-8">
            <Tabs
              onChange={function ka(v, value) {
                changeActiveTab(value);
              }}
              value={activeTab}
              variant="primary"
            >
              <Tab label="Описание" value={0} />
              <Tab label="Кандидаты" value={1} />
              <Tab label="История" value={2} />
            </Tabs>
          </div>

          {activeTab === 0 && (
            <VacationCardInfo
              data={{ ...vacancy, ...vacationCardData }}
            ></VacationCardInfo>
          )}

          {activeTab === 1 && (
            <>
              <CandidatesList
                candidatesData={candidatesData}
                isCandidatesLoading={isCandidateDataLoading}
                updateCandidateData={updateCandidateData}
                updateQueryParamsCandidate={updateQueryParamsCandidate}
                vacancy={vacancy}
              />
              {pageCount > 1 ? (
                <Pagination
                  className="pl-0 pagination"
                  currentNumber={queryParamsCandidate.pageNum}
                  onChange={function ka(page: any) {
                    updateQueryParamsCandidate({
                      ...queryParamsCandidate,
                      pageNum: page,
                    });
                  }}
                  total={pageCount}
                />
              ) : (
                ""
              )}
            </>
          )}
          {activeTab === 2 && (
            <>
              <History
                stateCode={vacationCardData.stateCode}
                vacancyId={vacationCardData.id}
              />
            </>
          )}
          {isOpenAddCandidateModal ? (
            <CreateCandidateModal
              clickOnClose={() => {
                changeIsOpenAddCandidateModal(false);
              }}
              onSuccessModal={() => {
                updateQueryParamsCandidate({
                  ...queryParamsCandidate,
                  rnd: generateRandomIdByLength(10),
                });
              }}
              vacancy={vacationCardData.id}
            />
          ) : null}
          {isShowVacancyPopup ? (
            <LinkWithVacancy
              clickOnClose={() => {
                setShowVacancyPopup(false);
                updateQueryParamsCandidate({
                  ...queryParamsCandidate,
                  rnd: generateRandomIdByLength(10),
                });
              }}
              type="vacancy"
              vacancy={{ ...vacancy, ...vacationCardData }}
            />
          ) : null}
          {isShowMassEffectPopup && (
            <MassEffectModal
              clickOnClose={() => {
                setShowMassEffect(false);
                updateVacancyCardData();
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
export default VacationCard;
