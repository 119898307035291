import { useResearcherAllQuery } from "../../../core/api/vacancy/vacancy";
import { VacancyUserModel } from "../../../types/vacation";
import style from "./CreateOrUpdateVacancy.module.scss";
import { InitField, ResearcherProps } from "./type";
import { Button, Input, Select, SelectData } from "@aurora/components";
import { GlyphPlus } from "@aurora/icons";
import cx from "classnames";
import { useEffect, useState } from "react";

const initFields: InitField[] = [
  { email: "", fullName: "", id: "", value: "" },
];

const ResearcherData: React.FC<ResearcherProps> = ({ setVacancy, vacancy }) => {
  const [fields, setFields] = useState<InitField[]>(initFields);
  const {
    data: ResearcherData,
    error: ResearcherError,
    isLoading: ResearcherLoading,
  } = useResearcherAllQuery();

  useEffect(() => {
    if (fields[0].id !== "") {
      const newVacancy = {
        ...vacancy,
        researcherIds: Array.from(new Set(fields.map(item => item.id))),
      };
      setVacancy(newVacancy);
    }
  }, [fields]);

  useEffect(() => {
    if (ResearcherData && !ResearcherLoading && !ResearcherError) {
      // предзаполнение полей данным с сервера
      if (vacancy.researcherIds && vacancy.researcherIds.length > 0) {
        const newField = vacancy.researcherIds.map((rId: string) => {
          return ResearcherData.filter(researcher => researcher.id === rId)[0];
        });
        setFields(newField);
      }
    }
  }, [ResearcherData, ResearcherLoading, ResearcherError]);

  const handleAddFields = () => {
    setFields([...fields, { email: "", fullName: "", id: "", value: "" }]);
  };

  // Обработчик изменения значений в строках
  const handleChange = (key: number, researcher: VacancyUserModel) => {
    const newFields = fields.map((f, k) =>
      k === key
        ? {
            email: researcher.email,
            fullName: researcher.fullName,
            id: researcher.id,
            value: researcher.fullName,
          }
        : f,
    );
    setFields(newFields);
  };

  return (
    <>
      {!ResearcherLoading && !ResearcherError && ResearcherData
        ? fields.map((field: InitField, key: number) => {
            return (
              <div
                key={key}
                className={cx("mt-16", style.CreateOrUpdateVacancyFormField)}
              >
                <div>
                  <Select
                    className={cx("p-0")}
                    data={ResearcherData as SelectData[]}
                    label={<span>Ресерчер</span>}
                    onChange={(researcher: any) => {
                      handleChange(key, researcher);
                    }}
                    shape="autocomplite"
                    value={field}
                  />
                </div>
                <div>
                  <Input
                    disabled={true}
                    label={<span>Электронная почта ресерчера</span>}
                    placeholder="Email ресерчера"
                    value={field.email}
                  />
                </div>
              </div>
            );
          })
        : ""}
      {ResearcherData &&
      ResearcherData.length > 0 &&
      fields.length !== ResearcherData.length ? (
        <div className={cx(style.ButtonAdd, "mt-8")}>
          <Button
            iconLeft={<GlyphPlus color={"#00000"} />}
            onClick={handleAddFields}
            variant={"secondary"}
          />
        </div>
      ) : (
        ""
      )}{" "}
    </>
  );
};

export default ResearcherData;
