import { useVacanciesListQuery } from "../../../../core/api/vacancy/vacancy";
import { generateRandomIdByLength } from "../../../../services/generate.service";
import { camelCaseToUnderscore } from "../../../../services/string.service";
import CandidateBadge from "../../../BaseComponents/CandidateBadge";
import NoData from "../../../BaseComponents/NoData";
import ChangeStatusPopup from "../../../BaseComponents/Popups/VacancyChangeStatus/Modal";
import { SkeletonTable } from "../../../BaseComponents/SkeletonTable/SkeletonTable";
import VacancyBadge from "../../../BaseComponents/VacancyBadge";
import {
  QueryParameters,
  SortCandidateCardField,
  SortDirectionCandidateCardField,
  SortDirectionUS,
  SortFieldUS,
  SortMapCandidateCard,
} from "../../Vacancies/types";
import styleCL from "../List/CandidatesList.module.scss";
import { SortDirection } from "../types";
import style from "./Card.module.scss";
import { Button, Pagination } from "@aurora/components";
import { GlyphSwap, SmallFall, SmallGrow } from "@aurora/icons";
import cx from "classnames";
import { ROUTE_VACANCIES } from "constants/routes";
import React, { FC, useEffect, useState } from "react";

interface Props {
  candidate: any;
  updateCandidateData?: any;
  vacancyIds?: any;
}
const initSortDirection: SortDirectionCandidateCardField = {
  createDate: undefined,
  statusTitle: undefined,
  title: undefined,
};

const sortMapping: SortMapCandidateCard = {
  createDate: "CREATE_DATE",
  statusTitle: "STATUS",
  title: "TITLE",
};

const CardVacancies: FC<Props> = ({
  candidate,
  updateCandidateData,
  vacancyIds,
}) => {
  const header = [
    {
      element: <div>Наименование вакансии</div>,
      item: "title",
    },
    { element: <div>Дата создания</div>, item: "createDate" },
    {
      element: <div>Статус вакансии</div>,
      item: "statusTitle",
    },
    { element: <div>Статус кандидата</div>, item: "statusCandidate" },
    { element: "", item: "actionBtn" },
  ];
  const [isModalStatusEditOpen, setIsModalStatusEditOpen] = useState(false);

  const [queryParams, setQueryParams] = useState<QueryParameters>({
    pageNum: 1,
    pageSize: 10,
    rnd: generateRandomIdByLength(10),
    vacancyIds,
  });
  const [pageCount, setPageCount] = useState(0);
  const [selectedCandidate, setSelectedCandidate] = useState<string>("");
  const [selectedVacancy, setSelectedVacancy] = useState<string>("");
  const [sortDirection, setSortDirection] =
    useState<SortDirectionCandidateCardField>(initSortDirection);

  const {
    data: vacanciesData,
    error: vacanciesError,
    isLoading: isVacanciesLoading,
  } = useVacanciesListQuery(queryParams, {
    refetchOnMountOrArgChange: true,
    skip: queryParams.vacancyIds?.length === 0,
  });

  useEffect(() => {
    if (vacanciesError) console.error("vacanciesError", vacanciesError);
  }, [vacanciesError]);

  useEffect(() => {
    if (vacanciesData?.total > 0) {
      setPageCount(Math.ceil(vacanciesData?.total / queryParams.pageSize));
    }
  }, [vacanciesData]);

  useEffect(() => {
    setQueryParams({ ...queryParams, vacancyIds });
  }, [vacancyIds]);

  useEffect(() => {
    if (!isVacanciesLoading) {
      let newQueryParams: QueryParameters = {
        ...queryParams,
      };
      if (sortDirection) {
        const sortFieldValue = Object.keys(sortDirection).find(
          v =>
            sortDirection[v as keyof SortDirectionCandidateCardField] !==
            undefined,
        );
        const sortFieldValueUS: SortFieldUS =
          sortMapping[sortFieldValue as keyof SortMapCandidateCard];
        const sortOrderValue = camelCaseToUnderscore(
          sortDirection[
            sortFieldValue as keyof SortDirectionCandidateCardField
          ],
        ) as SortDirectionUS;
        newQueryParams = {
          ...queryParams,
          sortField: sortFieldValueUS,
          sortOrder: sortOrderValue,
        };
      }
      setQueryParams({ ...newQueryParams });
    }
  }, [sortDirection]);

  const tableHead = header.map((item: any) => {
    const sortIcon = (
      <div
        className={cx(
          styleCL.CandidatesTable__icon,
          styleCL.CandidatesTable__icon__align_left,
        )}
      >
        {sortDirection[item.item as SortCandidateCardField] === "asc" && (
          <SmallFall data-testid="sort-down" />
        )}
        {sortDirection[item.item as SortCandidateCardField] === "desc" && (
          <SmallGrow data-testid="sort-up" />
        )}
      </div>
    );
    function onSort(item: SortCandidateCardField) {
      setSortDirection(prevState => {
        const currentDirection = prevState[item];
        let newDirection: SortDirection | undefined;
        if (currentDirection === undefined) {
          newDirection = "asc";
        } else if (currentDirection === "asc") {
          newDirection = "desc";
        } else {
          newDirection = undefined;
        }
        return { ...initSortDirection, [item]: newDirection };
      });
    }
    return (
      <th
        key={item.item}
        className={cx(
          item.item in sortDirection && styleCL.CandidatesTable__point,
        )}
        onClick={() => {
          if (item.item in sortDirection) onSort(item.item);
        }}
      >
        {item.item in sortDirection && sortIcon}
        {item.element}
      </th>
    );
  });

  return (
    <>
      {(!isVacanciesLoading && !vacanciesData?.total) || !vacancyIds.length ? (
        <NoData />
      ) : (
        <div className={cx(style.CandidateCardVacancies)}>
          <table className={cx("hr-table-style")}>
            <colgroup>
              <col width={"25%"} />
              <col width={"13.5%"} />
              <col width={"16.9%"} />
              <col width={"32.6%"} />
              <col width={"12%"} />
            </colgroup>
            <thead>
              <tr>{tableHead.map((element: any) => element)}</tr>
            </thead>
            <tbody>
              {isVacanciesLoading ? (
                <SkeletonTable rows={queryParams.pageSize} />
              ) : (
                <>
                  {vacanciesData?.items.map((item: any, idx: number) => (
                    <tr
                      key={idx}
                      className="can-click"
                      onClick={() => {
                        window.open(
                          `${ROUTE_VACANCIES}/?vacancyId=${item.id}`,
                          "_blank",
                          "noreferrer",
                        );
                      }}
                    >
                      <td className="truncate-cell" title={item.title}>
                        {item.title}
                      </td>
                      <td
                        className="truncate-cell"
                        title={new Date(item.createDate).toLocaleDateString()}
                      >
                        {new Date(item.createDate).toLocaleDateString()}
                      </td>
                      <td>
                        <VacancyBadge badge={item} />
                      </td>
                      <td>
                        {candidate?.candidates.length > 0 && (
                          <CandidateBadge
                            badge={
                              candidate.candidates.filter(
                                (v: any) => v.vacancyId === item.id,
                              )[0]
                            }
                          />
                        )}
                      </td>
                      <td className="text-right">
                        <Button
                          className={cx("mr-4", "bg-content-2", "text-black")}
                          iconLeft={<GlyphSwap />}
                          onClick={(e?: React.MouseEvent) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            setSelectedVacancy(item.id);
                            setSelectedCandidate(candidate.id);
                            setIsModalStatusEditOpen(true);
                          }}
                          variant="secondary"
                        ></Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
          {pageCount > 1 && (
            <Pagination
              className="pl-0 pagination"
              currentNumber={queryParams.pageNum}
              onChange={function ka(page: any) {
                setQueryParams({ ...queryParams, pageNum: page });
              }}
              total={pageCount}
            />
          )}
        </div>
      )}

      {isModalStatusEditOpen ? (
        <ChangeStatusPopup
          candidate={{ id: selectedCandidate }}
          clickOnClose={(needUpdateList = false) => {
            setIsModalStatusEditOpen(false);

            if (needUpdateList) {
              updateCandidateData({
                candidateId: candidate.id,
                rnd: generateRandomIdByLength(10),
              });
            }
          }}
          vacancy={{ id: selectedVacancy }}
        />
      ) : null}
    </>
  );
};

export default CardVacancies;
