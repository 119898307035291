export function checkEmail(text: string, ignoreEmpty = true) {
  if (ignoreEmpty && !text) return true;
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailPattern.test(text);
}

export function checkUsername(text: string, ignoreEmpty = true) {
  if (ignoreEmpty && !text) return true;

  const usernamePattern = /^[a-zA-Z0-9]{5,10}$/;

  return usernamePattern.test(text);
}

export function validateName(text: string) {
  if (text.charAt(0) === "-") {
    text = text.slice(1);
  }

  text = text.replace(/[^a-zA-Zа-яА-ЯёЁ-]/g, "");
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function checkPhone(text: string, ignoreEmpty = true) {
  if (ignoreEmpty && !text) return true;
  text = text.replace(/[^\d]/g, "");
  const phoneNumberPattern = /^[7-8]9[0-9 \(\)\-]+$/;

  return phoneNumberPattern.test(text) && text.length === 11;
}
export function isValidUsername(username: string, ignoreEmpty = true): boolean {
  if (ignoreEmpty && !username) return true;
  const pattern = /^@[a-zA-Z][a-zA-Z0-9_]{4,31}$/;
  return pattern.test(username);
}
export function weakEqual(a: any, b: any): boolean {
  // Приводим значения к числу или строке, если одно из них null или undefined
  const normalize = (value: any): any => {
    if (value === null || value === undefined) {
      return "";
    }
    return value;
  };

  // Нормализуем оба значения и сравниваем
  return normalize(a) == normalize(b);
}
export function sliceFirstChar(username: string) {
  if (username && username.startsWith("@")) return username.slice(1);
  return username;
}
