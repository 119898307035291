import { useEffect } from "react";

// Кастомный хук для обработки нажатия Enter
function useEnterKey(onEnter: () => void) {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onEnter();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    // Очищаем обработчик при размонтировании компонента
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onEnter]);
}

export default useEnterKey;
