import Label from "../../../BaseComponents/Label";
import style from "./VacancionCardInfo.module.scss";
import {
  InformationCell,
  MultiselectData,
  Skeleton,
  Text,
  UploadedFile,
} from "@aurora/components";
import { getFormattedFileSize } from "@aurora/components/dist/FileUploader/helpers";
import cx from "classnames";
import {
  useResearcherAllQuery,
  useRecruiterAllQuery,
  useVacationCategoriesQuery,
  useVacationGetFileInfoMutation,
  vacationCustomApi,
} from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";

interface Props {
  data?: any;
}

interface Participants {
  id: string;
  fullName: string;
  email: string;
}

const VacationCardInfo: FC<Props> = ({ data }: any) => {
  const [fileVacancy, changeFileVacancy]: any = useState<File | null>();
  const [fileVacancyInfo, changeFileInfo]: any = useState(null);
  const [labels, setLabels] = useState<MultiselectData[]>([]);
  const [isParticipantsVacancy, setParticipantsVacancy] = useState(false);
  const [recruiter, setRecruiter] = useState<Participants[]>([]);
  const [researcher, setResearcher] = useState<Participants[]>([]);

  const {
    data: CategoriesData,
    error: CategoriesError,
    isLoading: isCategoriesLoading,
  } = useVacationCategoriesQuery();
  const {
    data: RecruiterData,
    error: RecruiterError,
    isLoading: isRecruiterLoading,
  } = useRecruiterAllQuery();
  const {
    data: ResearcherData,
    error: ResearcherError,
    isLoading: isResearcherLoading,
  } = useResearcherAllQuery();

  const [getFileInfo] = useVacationGetFileInfoMutation();

  useEffect(() => {
    if (data?.labels.length > 0) {
      const labelsMap: MultiselectData[] = [...data.labels];
      setLabels(labelsMap);
    }
    if (data.managerId || data.recruiterId || data.researcherIds.length > 0) {
      setParticipantsVacancy(true);
    }
  }, [data]);
  // отображение ошибки в консоли
  useEffect(() => {
    if (CategoriesError) console.error("CategoriesError", CategoriesError);
  }, [CategoriesError]);

  useEffect(() => {
    if (RecruiterData && !isRecruiterLoading && !RecruiterError) {
      const newRecruiter = RecruiterData.filter(
        r => r.id === data.recruiterId,
      ) as Participants[];
      setRecruiter(newRecruiter);
    }
  }, [RecruiterData, isRecruiterLoading, RecruiterError]);
  useEffect(() => {
    if (ResearcherData && !isResearcherLoading && !ResearcherError) {
      const newResearcher = ResearcherData.filter(r =>
        data.researcherIds.includes(r.id),
      ) as Participants[];
      setResearcher(newResearcher);
    }
  }, [ResearcherData, isResearcherLoading, ResearcherError]);

  useEffect(() => {
    if (!data.fileUploaded) return;
    Promise.all([
      vacationCustomApi.downloadFile({ vacancyId: data.id }),
      getFileInfo({ vacancyId: data.id }),
    ]).then(values => {
      const file = values[0];
      const info: any = values[1];
      const vacancyFile = new File([file.data], info.data.fileName);
      changeFileInfo(info.data);
      changeFileVacancy(vacancyFile);
    });
  }, []);

  function openPdfInNewTab() {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(fileVacancy);
    link.download = fileVacancyInfo.fileName;
    link.dispatchEvent(new MouseEvent("click"));
  }

  const currentCategory = CategoriesData.filter(
    (cat: any) => cat.id === data.categoryId,
  )[0];

  const vacancyData = [
    {
      label: "Название вакансии",
      value: data.title,
    },
    {
      label: "Ссылка на вакансию",
      value: data.link ? (
        <a
          className={cx("link")}
          href={data.link}
          rel="noreferrer"
          target="_blank"
        >
          Перейти
        </a>
      ) : null,
    },
    {
      label: "Категория",
      value: currentCategory.value,
    },
    {
      label: "Контакты нанимающего менеджера",
      value: data.managerContacts,
    },
    {
      label: "Дата создания",
      value: data.createDate,
    },
    {
      label: "Дней в работе",
      value: String(data.inProgressDays) || "-",
    },
    {
      label: "Локация",
      value: data.location,
    },
  ];

  //если данных нет, то не отображаем соответствующий блок
  const filteredVacancyData = vacancyData.filter(item => item.value);

  return (
    <div>
      <div className={cx("border-bottom pb-16")}>
        <div className={style.VacationCardInfo}>
          {filteredVacancyData.map((item, idx) => (
            <InformationCell
              key={idx}
              label={
                <Text tag="div" type="labelRegular">
                  {item.label}
                </Text>
              }
              separator={false}
              title={item.value}
            />
          ))}
        </div>
      </div>
      {isParticipantsVacancy ? (
        <div className={cx("border-bottom pb-16")}>
          <div className={style.VacationCardInfo}>
            <InformationCell
              key={"pv-1"}
              label={
                <Text tag="div" type="labelRegular">
                  Нанимающий менеджер
                </Text>
              }
              separator={false}
              title={data.managerFullName || "-"}
            />
            {recruiter.length ? (
              <InformationCell
                key={"pv-2"}
                label={
                  <Text tag="div" type="labelRegular">
                    {"Рекрутер"}
                    {recruiter.length > 1 ? "ы" : ""}
                  </Text>
                }
                separator={false}
                title={recruiter.map((r, i) => (
                  <div key={i}>{r.fullName}</div>
                ))}
              />
            ) : null}
            {researcher.length ? (
              <>
                <InformationCell
                  key={"pv-3"}
                  label={
                    <Text tag="div" type="labelRegular">
                      {"Рессерчер"}
                      {researcher.length > 1 ? "ы" : ""}
                    </Text>
                  }
                  separator={false}
                  title={researcher.map((r, i) => (
                    <div key={i}>{r.fullName}</div>
                  ))}
                  variant={"info"}
                />
              </>
            ) : null}
          </div>
        </div>
      ) : null}
      {data.requirements ? (
        <div className="mt-16">
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Требования к кандидату
              </Text>
            }
            separator={false}
            title={<div className="formatted-text">{data.requirements}</div>}
          />
        </div>
      ) : null}

      {data.responsibilities ? (
        <div>
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Задачи вакансии
              </Text>
            }
            separator={false}
            title={
              <div className="formatted-text">{data.responsibilities}</div>
            }
          />
        </div>
      ) : null}

      {data.description ? (
        <div>
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Описание вакансии для шаблона-приглашения
              </Text>
            }
            separator={false}
            title={<span className="formatted-text">{data.description}</span>}
          />
        </div>
      ) : null}

      {data.options ? (
        <div className="pb-16 border-bottom">
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Условия вакансии
              </Text>
            }
            separator={false}
            title={<div className="formatted-text">{data.options}</div>}
          />
        </div>
      ) : null}
      {data.id ? (
        <Label
          entity={"vacancy"}
          initLabels={labels}
          needConfirmed={true}
          needSaveBt={true}
          onLabelsChange={setLabels}
          vacancyId={data.id}
        />
      ) : null}
      {data.fileUploaded ? (
        <div className="pt-16 border-top">
          <InformationCell
            label={
              <Text tag="div" type="labelRegular">
                Загруженные файлы
              </Text>
            }
            separator={false}
            title=""
          />

          {fileVacancy ? (
            <a
              className={"text-decoration-none"}
              onClick={() => {
                openPdfInNewTab();
              }}
            >
              {" "}
              <UploadedFile
                description={getFormattedFileSize(fileVacancy.size)}
                errorDescription="error description"
                file={fileVacancy}
                indent={false}
                loadingDescription="loading"
                shape="cardCell"
                shortDescription="short"
                showDeleteButton={false}
                status="FILE_SUCCESS"
                title="Файл вакансии"
              />
            </a>
          ) : (
            <div>
              <Skeleton
                height={64}
                radius="r5"
                shape="line"
                variant="primary"
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VacationCardInfo;
