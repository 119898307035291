import { LabelSearchModel } from "../../Candidates/types";
import style from "./FilterList.module.scss";
import {
  Button,
  Multiselect,
  InputDatepicker,
  MultiselectData,
  Checkbox,
  RadioFormGroup,
  Radio,
} from "@aurora/components";
import { GlyphSearch } from "@aurora/icons";
import cx from "classnames";
import FilterListLoader from "components/BaseComponents/FilterListLoader";
import { useCitiesQuery, useLabelsByPartTextQuery } from "core/api/base/other";
import {
  useVacationAutocompleteTitleQuery,
  useVacationAutocompleteManagerQuery,
  useVacationStatusesQuery,
  useVacationCategoriesQuery,
  useVacationAutocompleteRecruiterQuery,
} from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatDateUTC } from "services/date.service";

interface IVacanciesContext {
  setQueryParams: (params: any) => void;
}

interface State {
  title: string;
  manager: string;
  vacancyLocations: any;
  CategoryField: any;
  StatusField: any;
  activeOnly: boolean;
  delayedOnly: boolean;
  datepickerValue: any;
}

function initialState(): State {
  return {
    CategoryField: [],
    StatusField: [],
    activeOnly: true,
    datepickerValue: [null, null],
    delayedOnly: false,
    manager: "",
    title: "",
    vacancyLocations: [],
  };
}

const FilterList: FC<IVacanciesContext> = ({ setQueryParams }) => {
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );
  const [state, setState] = useState(initialState);
  const [needClear, setNeedClear] = useState(false);
  const [isFirstLoad, changeFirstLoad] = useState(true);

  const [autocompleteTitle, changeAutocompleteTitle] = useState("");
  const [autocompleteManager, changeAutocompleteManager] = useState("");
  const [autocompleteCities, changeAutocompleteCities] = useState("");
  const [labelAutocomplete, setLabelAutocomplete] = useState("");
  const [autocompleteRecruiter, setAutocompleteRecruiter] = useState("");

  const [vacancyOption, setVacancyOption] = useState<MultiselectData[]>([]);
  const [managerOption, setManagerOption] = useState<MultiselectData[]>([]);
  const [locationOption, setLocationOption] = useState<MultiselectData[]>([]);
  const [labelData, setLabelData] = useState<MultiselectData[]>([]);
  const [recruiterOption, setRecruiterOption] = useState<MultiselectData[]>([]);
  const [labelSearchLogic, setLabelSearchLogic] =
    useState<LabelSearchModel>("OR");

  const handleChange = (field: keyof State, value: any) => {
    setState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleReset = () => {
    setVacancyOption([]);
    setManagerOption([]);
    setLocationOption([]);
    setState(initialState());
    setLabelSearchLogic("OR");
    setLabelData([]);
    setNeedClear(true);
  };

  const user = useSelector((store: any) => store.userDataSlice.user);

  useEffect(() => {
    if (!needClear) return;
    // handleSubmit();
    setNeedClear(false);
  }, [needClear]);

  useEffect(() => {
    if (isFirstLoad) {
      changeFirstLoad(false);
      return;
    }
    handleSubmit();
  }, [
    vacancyOption,
    managerOption,
    locationOption,
    state,
    labelData,
    labelSearchLogic,
    recruiterOption,
  ]);

  const handleSubmit = () => {
    setQueryParams((oldParams: any) => {
      return {
        ...oldParams,
        activeOnly: state.activeOnly,
        categoryIds: state.CategoryField.map((category: any) => {
          return category.id;
        }),
        createPeriodEnd: formatDateUTC(state.datepickerValue[1]),
        createPeriodStart: formatDateUTC(state.datepickerValue[0]),
        delayedOnly: state.delayedOnly,
        labelIds: labelData.map(label => label.id),
        labelSearchMode: labelSearchLogic,
        managerIds: managerOption.map(manager => {
          return manager.id;
        }),
        pageNum: 1,
        recruiterIds: recruiterOption.map(recruiter => recruiter.id),
        stateCodes: state.StatusField.map((status: any) => {
          return status.code;
        }),
        vacancyLocations: locationOption.map(location => {
          return location.value;
        }),
        vacancyTitles: vacancyOption.map(vacancy => {
          return vacancy.value;
        }),
      };
    });
  };

  const {
    data: CategoriesData,
    error: CategoriesError,
    isLoading: isCategoriesLoading,
  } = useVacationCategoriesQuery();
  const {
    data: StatueseData,
    error: StatueseError,
    isLoading: isStatueseLoading,
  } = useVacationStatusesQuery();
  const {
    data: CitiesData,
    error: CitiesError,
    isLoading: isCitiesLoading,
  } = useCitiesQuery();

  const {
    data: AutocompleteTitleData,
    error: TitleAutocompleteError,
    isLoading: titleAutocompleteLoader,
  } = useVacationAutocompleteTitleQuery(autocompleteTitle, {
    skip: autocompleteTitle.length < 3,
  });
  const {
    data: AutocompleteManagerData,
    error: ManagerAutocompleteError,
    isLoading: managerAutocompleteLoader,
  } = useVacationAutocompleteManagerQuery(autocompleteManager, {
    skip: autocompleteManager.length < 3,
  });
  const {
    data: labelAutocompleteData,
    error: labelAutocompleteError,
    isLoading: labelAutocompleteLoading,
  } = useLabelsByPartTextQuery(labelAutocomplete, {
    skip: labelAutocomplete.length < 3,
  });
  const {
    data: recruiterAutocompleteData,
    error: recruiterAutocompleteError,
    isLoading: recruiterAutocompleteLoading,
  } = useVacationAutocompleteRecruiterQuery(autocompleteRecruiter, {
    skip: autocompleteRecruiter.length < 3,
  });

  useEffect(() => {
    if (labelAutocompleteError)
      console.error("labelAutocompleteError", labelAutocompleteError);
    if (ManagerAutocompleteError)
      console.error("ManagerAutocompleteError", ManagerAutocompleteError);
    if (TitleAutocompleteError)
      console.error("TitleAutocompleteError", TitleAutocompleteError);
    if (CitiesError) console.error("CitiesError", CitiesError);
    if (StatueseError) console.error("StatueseError", StatueseError);
    if (CategoriesError) console.error("CategoriesError", CategoriesError);
    if (recruiterAutocompleteError)
      console.error("recruiterAutocompleteError", recruiterAutocompleteError);
  }, [
    labelAutocompleteError,
    ManagerAutocompleteError,
    TitleAutocompleteError,
    CitiesError,
    StatueseError,
    CategoriesError,
    recruiterAutocompleteError,
  ]);

  useEffect(() => {
    if (labelAutocompleteError)
      console.error("labelAutocompleteError", labelAutocompleteError);
    if (ManagerAutocompleteError)
      console.error("ManagerAutocompleteError", ManagerAutocompleteError);
    if (TitleAutocompleteError)
      console.error("TitleAutocompleteError", TitleAutocompleteError);
    if (CitiesError) console.error("CitiesError", CitiesError);
    if (StatueseError) console.error("StatueseError", StatueseError);
    if (CategoriesError) console.error("CategoriesError", CategoriesError);
  }, [
    labelAutocompleteError,
    ManagerAutocompleteError,
    TitleAutocompleteError,
    CitiesError,
    StatueseError,
    CategoriesError,
  ]);

  return (
    <>
      {isStatueseLoading || isCategoriesLoading || isCitiesLoading ? (
        <FilterListLoader />
      ) : (
        <>
          <div className="mb-8">
            <Multiselect
              addonBefore={<GlyphSearch />}
              className="mb-0"
              data={AutocompleteTitleData || []}
              indent={false}
              loading={titleAutocompleteLoader}
              onChange={setVacancyOption}
              onInputChange={changeAutocompleteTitle}
              placeholder="Название вакансии"
              shape="select"
              showArrow={false}
              value={vacancyOption}
            />
          </div>
          {!isManagerOrWatcher && (
            <div className="mb-8">
              <Multiselect
                addonBefore={<GlyphSearch />}
                className="mb-0"
                data={AutocompleteManagerData || []}
                indent={false}
                loading={managerAutocompleteLoader}
                onChange={setManagerOption}
                onInputChange={(value: string) => {
                  changeAutocompleteManager(value);
                }}
                placeholder="Имя менеджера"
                shape="select"
                showArrow={false}
                value={managerOption}
              />
            </div>
          )}
          <div className="mb-8">
            <Multiselect
              addonBefore={<GlyphSearch />}
              className="mb-0"
              data={recruiterAutocompleteData || []}
              indent={false}
              loading={recruiterAutocompleteLoading}
              onChange={setRecruiterOption}
              onInputChange={(value: string) => {
                setAutocompleteRecruiter(value);
              }}
              placeholder="Имя основного рекрутера"
              shape="select"
              showArrow={false}
              value={recruiterOption}
            />
          </div>
          <div className="mb-8">
            <Multiselect
              addonBefore={<GlyphSearch />}
              className="mb-0"
              data={CitiesData || []}
              indent={false}
              loading={isCitiesLoading}
              onChange={setLocationOption}
              onInputChange={(value: string) => {
                changeAutocompleteCities(value);
              }}
              placeholder="Локация"
              shape="select"
              showArrow={false}
              value={locationOption}
            />
          </div>
          <div className="mb-8">
            <Multiselect
              className={cx("p-0")}
              data={CategoriesData}
              onChange={(value: any) => handleChange("CategoryField", value)}
              placeholder="Категория"
              value={state.CategoryField}
            />
          </div>
          <div className="mb-8">
            <Multiselect
              className={cx("p-0")}
              data={StatueseData}
              onChange={(value: any) => handleChange("StatusField", value)}
              placeholder="Статус"
              value={state.StatusField}
            />
          </div>
          <div className="mb-8">
            <InputDatepicker
              classNameBackgroundColor={undefined}
              dates={state.datepickerValue}
              disabled={false}
              onBlurContainerField={undefined}
              onFocusContainerField={undefined}
              onSubmit={dates => handleChange("datepickerValue", dates)}
              placeholder={"Дата создания"}
            />
          </div>
          <div className={cx(style.LabelSearchBlock, "mb-8")}>
            <Multiselect
              addonBefore={<GlyphSearch />}
              className="mb-0"
              data={labelAutocompleteData || []}
              indent={false}
              loading={labelAutocompleteLoading}
              onChange={setLabelData}
              onInputChange={setLabelAutocomplete}
              placeholder="Метки"
              shape="select"
              showArrow={false}
              value={labelData}
            />
            <RadioFormGroup
              defaultValue={"OR"}
              name={"labelSearchMode"}
              onChange={(e: any) => {
                setLabelSearchLogic(e.target.value as LabelSearchModel);
              }}
              shape={"horizontal"}
            >
              <Radio
                checked={labelSearchLogic === "OR"}
                label={"Или"}
                value={"OR"}
              />
              <Radio
                checked={labelSearchLogic === "AND"}
                label={"И"}
                value={"AND"}
              />
            </RadioFormGroup>
          </div>
          <div className="mb-8">
            <Checkbox
              checked={state.activeOnly}
              label="Только активные"
              onChange={() => handleChange("activeOnly", !state.activeOnly)}
            />
          </div>
          <div className="mb-8">
            <Checkbox
              checked={state.delayedOnly}
              label="В просрочке"
              onChange={() => handleChange("delayedOnly", !state.delayedOnly)}
            />
          </div>
          <Button onClick={handleReset} variant="secondary">
            Сбросить
          </Button>
        </>
      )}
    </>
  );
};
export default FilterList;
