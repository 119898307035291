import useErrorNotification from "../../../hooks/useErrorNotification";
import { isEqual } from "../../../services/object.service";
import Label from "../../BaseComponents/Label";
import style from "./CreateOrUpdateVacancy.module.scss";
import RecruiterData from "./RecruiterData";
import ResearcherData from "./ResearcherData";
import CreateOrUpdateVacancySkeleton from "./Skeleton/Skeleton";
import {
  Button,
  Input,
  FileUploader,
  Select,
  SelectData,
  TextArea,
  useSnackbars,
  Tooltip,
  MultiselectData,
} from "@aurora/components";
import cx from "classnames";
import { DICTIONARY } from "constants/dictionary";
import {
  useAddNewLabelMutation,
  useCitiesQuery,
  useManagersQuery,
} from "core/api/base/other";
import {
  useAddVacancyFileMutation,
  useBindLabelsToVacancyMutation,
  useCreateVacancyMutation,
  useDeleteVacancyFileMutation,
  useUpdateVacancyMutation,
  useVacationCardMutation,
  useVacationCategoriesQuery,
  useVacationGetFileInfoMutation,
  vacationCustomApi,
} from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { snackbarFail, snackbarSuccess } from "services/snackbar.service";
import { validate as isValidUUID } from "uuid";

interface Props {
  isEdit?: boolean;
}

const initialFormState = {
  categoryId: "",
  closePeriodDays: "",
  description: "",
  labels: [],
  link: "",
  location: "",
  managerContacts: "",
  managerId: "",
  options: "",
  positionTotalCount: "",
  recruiterId: null,
  requirements: "",
  researcherIds: [],
  responsibilities: "",
  title: "",
};

const CreateOrUpdateVacancy: FC<Props> = () => {
  const { snackbarInfo } = useSnackbars();

  const navigate = useNavigate();
  const routeParams = useParams();
  const errorNotification = useErrorNotification();
  const [isFileChanges, setFileChanges] = useState(false);

  const [selectedOptionCategories, setSelectedOptionCategories] =
    useState<SelectData | null>(null);
  const [selectedOptionCities, setSelectedOptionCities] =
    useState<SelectData | null>(null);
  const [selectedOptionManager, setSelectedOptionManager] =
    useState<SelectData | null>(null);
  const [fileUploader, setFileUploader] = useState<any>([]);
  const [newLabels, setLabels] = useState<MultiselectData[]>([]);
  const [vacancy, setVacancy]: any = useState(initialFormState);
  const [isVacationCardLoading, changeVacationCardLoading] = useState(true);

  const [hasAnyChanges, changeHasAnyChanges] = useState(false);
  const [hasLabelsChanges, changeHasLabelsChanges] = useState(false);
  const [isLoading, changeLoading] = useState(true);

  const [vacationCardData, changeVacationCardData]: any = useState(null);

  const isEdit = !!routeParams.vacancyId;

  const [createVacation] = useCreateVacancyMutation();
  const [getFileInfo] = useVacationGetFileInfoMutation();

  const [updateVacation] = useUpdateVacancyMutation();

  const [attachFile] = useAddVacancyFileMutation();
  const [deleteFile] = useDeleteVacancyFileMutation();

  const {
    data: CategoriesData,
    error: CategoriesError,
    isLoading: isCategoriesLoading,
  } = useVacationCategoriesQuery();
  const {
    data: CitiesData,
    error: CitiesError,
    isLoading: isCitiesLoading,
  } = useCitiesQuery();
  const {
    data: ManagersData,
    error: ManagersError,
    isLoading: isManagersLoading,
  } = useManagersQuery();

  const [loadDataCard] = useVacationCardMutation();
  const [addNewLabel] = useAddNewLabelMutation();
  const [bindLabelsToVacancy] = useBindLabelsToVacancyMutation();

  useEffect(() => {
    if (CategoriesError) console.error("CategoriesError", CategoriesError);
    if (ManagersError) console.error("ManagersError", ManagersError);
    if (CitiesError) console.error("CitiesError", CitiesError);
  }, [ManagersError, CitiesError, CategoriesError]);

  function loadCardDataInfo() {
    changeHasLabelsChanges(false);
    changeHasAnyChanges(false);
    loadDataCard({ vacancyId: routeParams.vacancyId }).then(
      (vacationCardData: any) => {
        vacationCardData = vacationCardData.data;
        changeVacationCardData(vacationCardData);

        if (vacationCardData.fileUploaded) {
          Promise.all([
            vacationCustomApi.downloadFile({
              vacancyId: routeParams.vacancyId,
            }),
            getFileInfo({ vacancyId: routeParams.vacancyId }),
          ]).then(values => {
            const file = values[0];
            const info: any = values[1];
            const vacancyFile = new File([file.data], info.data.fileName);
            setFileUploader([vacancyFile]);
            changeVacationCardLoading(false);
          });
        } else {
          changeVacationCardLoading(false);
        }
      },
    );
  }

  useEffect(() => {
    // Загрузка основной информации и файла
    if (isEdit) loadCardDataInfo();
  }, []);

  useEffect(() => {
    setVacancy({ ...vacancy, labels: newLabels });
  }, [newLabels]);

  useEffect(() => {
    // Создание карточки вакансии
    if (isEdit) return;
    if (isCategoriesLoading || isCitiesLoading || isManagersLoading) return;
    changeHasAnyChanges(true);
    changeLoading(false);
  }, [isCategoriesLoading, isCitiesLoading, isManagersLoading]);

  useEffect(() => {
    // Редактирование существующей карточки, логика отработает после того как все данные будут загружены
    if (!isEdit) return;
    if (
      isVacationCardLoading ||
      isCategoriesLoading ||
      isCitiesLoading ||
      isManagersLoading
    )
      return;

    setVacancy({
      categoryId: vacationCardData.categoryId,
      closePeriodDays: vacationCardData.closePeriodDays,
      description: vacationCardData.description,
      labels: vacationCardData.labels,
      link: vacationCardData.link,
      location: vacationCardData.location,
      managerContacts: vacationCardData.managerContacts,
      managerId: vacationCardData.managerId,
      options: vacationCardData.options,
      positionTotalCount: vacationCardData.positionTotalCount,
      recruiterId: vacationCardData.recruiterId,
      requirements: vacationCardData.requirements,
      researcherIds: vacationCardData.researcherIds,
      responsibilities: vacationCardData.responsibilities,
      title: vacationCardData.title,
    });

    setSelectedOptionCategories(
      CategoriesData.filter(
        (cat: any) => vacationCardData.categoryId === cat.id,
      )[0],
    );
    setSelectedOptionCities(
      CitiesData.filter(
        (city: any) => vacationCardData.location === city.value,
      )[0],
    );
    setSelectedOptionManager(
      ManagersData.filter(
        (manager: any) => vacationCardData.managerId === manager.id,
      )[0],
    );

    changeLoading(false);
  }, [
    isVacationCardLoading,
    isCategoriesLoading,
    isCitiesLoading,
    isManagersLoading,
  ]);

  useEffect(() => {
    if (!isEdit || isLoading) return;
    hasAnyChangesVacancy();
  }, [
    vacancy,
    selectedOptionManager,
    selectedOptionCategories,
    selectedOptionCities,
    isFileChanges,
    newLabels,
  ]);

  function hasAnyChangesVacancy() {
    let hasChanges = false;
    let labelChanges = false;

    Object.keys(vacancy).forEach((key: string) => {
      if (key !== "labels" && vacationCardData[key] != vacancy[key])
        hasChanges = true;
      if (
        key === "labels" &&
        !isEqual(vacationCardData[key], vacancy[key], true)
      )
        labelChanges = true;
    });

    if (
      vacationCardData.managerId !== selectedOptionManager?.id ||
      vacationCardData.categoryId !== selectedOptionCategories?.id ||
      (vacationCardData.location &&
        vacationCardData.location !== selectedOptionCities?.value) ||
      (!vacationCardData.location && selectedOptionCities?.value)
    ) {
      hasChanges = true;
    }

    if (isFileChanges) hasChanges = true;
    changeHasLabelsChanges(labelChanges);
    changeHasAnyChanges(hasChanges);
  }

  function isDisabledCreateButton() {
    return !(
      vacancy.title &&
      vacancy.description &&
      vacancy.managerContacts &&
      vacancy.closePeriodDays &&
      vacancy.positionTotalCount &&
      vacancy.recruiterId &&
      selectedOptionCategories &&
      selectedOptionManager &&
      (hasAnyChanges || hasLabelsChanges)
    );
  }

  async function workWithLabels(
    vacancyLabels: MultiselectData[],
    vacancyId?: string,
  ) {
    const vId = vacancyId ?? "";
    const newLabels: MultiselectData[] = [
      ...vacancyLabels.filter(
        (label: MultiselectData) => !isValidUUID(label.id),
      ),
    ];
    return Promise.all([
      ...vacancyLabels
        .filter((label: MultiselectData) => isValidUUID(label.id))
        .map((label: MultiselectData) => addNewLabel(label)),
    ]).then(async result => {
      result.map((d: any) => {
        if (!d.data) return;
        newLabels.push({ ...d.data });
      });
      return bindLabels([...newLabels], vId)
        .then((bindLabelsResult: any) => {
          if (bindLabelsResult.data) {
            setVacancy({ ...vacancy, labels: bindLabelsResult.data.labels });
            setLabels(bindLabelsResult.data.labels);
            snackbarInfo?.show(snackbarSuccess(DICTIONARY.LABEL_ADD_TO_CARD));
          }
        })
        .catch(e => {
          if (!(e instanceof RangeError)) {
            console.log("Error", e);
            const fail: any = snackbarFail(DICTIONARY.FAIL);
            snackbarInfo?.show(fail);
          }
        });
    });
  }

  async function bindLabels(dataLabels: MultiselectData[], vacancyId: string) {
    // если массив лейблов пустой отменяем формирование запроса
    if (dataLabels.length === 0)
      return Promise.reject(new RangeError("rejected"));
    // для всех меток id которых нет в initLabels сделаем привязку
    return await bindLabelsToVacancy({
      labelIds: !routeParams.vacancyId
        ? dataLabels.map(label => label.id)
        : dataLabels
            .filter(
              label =>
                !vacationCardData.labels.some(
                  (value: MultiselectData) => label.id === value.id,
                ),
            )
            .map(label => label.id),
      vacancyId,
    });
  }

  async function createVacancyOrUpdate() {
    let vacation: any = null;

    changeLoading(true);
    let successText = "";

    if (isEdit) {
      if (hasLabelsChanges) {
        await workWithLabels(vacancy.labels, routeParams.vacancyId).finally(
          async () => {
            vacation = await updateVacation({
              ...vacancy,
              categoryId: selectedOptionCategories?.id,
              id: routeParams.vacancyId,
              location: selectedOptionCities?.value,
              managerId: selectedOptionManager?.id,
            });
            changeHasLabelsChanges(false);
          },
        );
      } else {
        vacation = await updateVacation({
          ...vacancy,
          categoryId: selectedOptionCategories?.id,
          id: routeParams.vacancyId,
          location: selectedOptionCities?.value,
          managerId: selectedOptionManager?.id,
        });
      }
      successText = DICTIONARY.CHANGE_VACANCY;
    } else {
      await createVacation({
        ...vacancy,
        categoryId: selectedOptionCategories?.id,
        location: selectedOptionCities?.value,
        managerId: selectedOptionManager?.id,
      }).then((result: any) => {
        vacation = result;
        if (!vacation.error) {
          workWithLabels(newLabels, vacation.data.id);
        }
      });
      successText = DICTIONARY.SUCCESS_VACANCY;
    }

    if (vacation.error) {
      errorNotification(vacation.error);
      changeLoading(false);
      return;
    }

    if (fileUploader.length && vacation.data.id && isFileChanges) {
      const formData = new FormData();
      formData.append("document", fileUploader[0]);

      await attachFile({
        document: formData,
        vacancyId: vacation?.data?.id,
      });
    }

    if (!fileUploader.length && isFileChanges && vacation.data.id) {
      await deleteFile({
        vacancyId: vacation?.data?.id,
      });
    }

    snackbarInfo?.show(snackbarSuccess(successText));
    changeLoading(false);

    if (!isEdit) {
      navigate(-1);
      return;
    }
    loadCardDataInfo();
  }

  return (
    <>
      {isLoading ? (
        <CreateOrUpdateVacancySkeleton />
      ) : (
        <div className={style.CreateOrUpdateVacancy}>
          <div className={style.CreateOrUpdateVacancyHeader}>
            <h1 className={cx("my-0 text-black")}>
              {isEdit ? "Редактировать вакансию" : "Новая вакансия"}
            </h1>

            <div>
              <Button
                className={cx(style.ButtonStyles, "mr-4")}
                onClick={() => {
                  navigate(-1);
                }}
                variant="secondary"
              >
                Отмена
              </Button>

              <Button
                className={style.ButtonStyles}
                disabled={isDisabledCreateButton()}
                onClick={createVacancyOrUpdate}
                variant="primary"
              >
                Сохранить
              </Button>
            </div>
          </div>

          <div className={cx(style.CreateOrUpdateVacancyForm, "px-8")}>
            <h2 className="my-0">Информация о вакансии</h2>
            <div className={cx("mt-16", style.CreateOrUpdateVacancyFormField)}>
              <div>
                <Input
                  label={
                    <span>
                      Название вакансии{" "}
                      <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(e: any) =>
                    setVacancy({ ...vacancy, title: e.target.value })
                  }
                  onClear={() => setVacancy({ ...vacancy, title: "" })}
                  placeholder="Введите"
                  value={vacancy.title}
                />
              </div>
              <div>
                <Select
                  className={cx("p-0")}
                  data={CategoriesData}
                  label={
                    <span>
                      Категория <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(d: any) => {
                    setSelectedOptionCategories(d);
                    setVacancy({
                      ...vacancy,
                      closePeriodDays: d.closePeriodDays,
                    });
                  }}
                  shape="autocomplite"
                  value={selectedOptionCategories}
                />
              </div>
              <div>
                <Input
                  label={
                    <span>
                      Срок закрытия вакансии{" "}
                      <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(e: any) => {
                    const onlyNumbers = e.target.value.replace(/\D/g, "");
                    setVacancy({ ...vacancy, closePeriodDays: onlyNumbers });
                  }}
                  onClear={() =>
                    setVacancy({ ...vacancy, closePeriodDays: "" })
                  }
                  placeholder="Введите"
                  value={vacancy.closePeriodDays}
                />
              </div>
            </div>
            <div
              className={cx(
                "mt-8 pt-8",
                style.CreateOrUpdateVacancyFormField,
                "border-top",
              )}
            >
              <div>
                <TextArea
                  indent={false}
                  onChange={(e: any) => {
                    setVacancy({ ...vacancy, requirements: e.value });
                  }}
                  placeholder="Введите"
                  rows={3}
                  shape="default"
                  title="Требования к кандидату"
                  value={{ value: vacancy.requirements }}
                />
              </div>
              <div>
                <TextArea
                  indent={false}
                  onChange={(e: any) =>
                    setVacancy({ ...vacancy, responsibilities: e.value })
                  }
                  placeholder="Введите"
                  rows={3}
                  shape="default"
                  title="Задачи вакансии"
                  value={{ value: vacancy.responsibilities }}
                />
              </div>
            </div>

            <div className={cx("mt-16")}>
              <div>
                <TextArea
                  indent={false}
                  onChange={(e: any) => {
                    setVacancy({ ...vacancy, options: e.value });
                  }}
                  placeholder="Введите"
                  rows={3}
                  shape="default"
                  title="Условия вакансии"
                  value={{ value: vacancy.options }}
                />
              </div>
            </div>

            <div className={cx("mt-16", style.CreateOrUpdateVacancyFormField)}>
              <div>
                <Input
                  label={
                    <span>
                      Кол-во позиций{" "}
                      <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(e: any) => {
                    const onlyNumbers = e.target.value.replace(/\D/g, "");
                    const sanitizedValue = Math.min(onlyNumbers * 1, 1000000);

                    setVacancy({
                      ...vacancy,
                      positionTotalCount: String(sanitizedValue),
                    });
                  }}
                  onClear={() =>
                    setVacancy({ ...vacancy, positionTotalCount: "" })
                  }
                  placeholder="Введите"
                  value={vacancy.positionTotalCount}
                />
              </div>
              <div>
                <Input
                  label="Ссылка на вакансию"
                  onChange={(e: any) =>
                    setVacancy({ ...vacancy, link: e.target.value })
                  }
                  onClear={() => setVacancy({ ...vacancy, link: "" })}
                  placeholder="Введите"
                  value={vacancy.link}
                />
              </div>
              <div>
                <Select
                  className={cx("p-0")}
                  data={CitiesData}
                  label="Локация"
                  onChange={setSelectedOptionCities}
                  shape="autocomplite"
                  value={selectedOptionCities}
                />
              </div>
            </div>
            <Label
              entity={"vacancy"}
              initLabels={
                routeParams.vacancyId
                  ? vacationCardData?.labels || []
                  : newLabels
              }
              needConfirmed={!!routeParams.vacancyId}
              needSaveBt={false}
              onLabelsChange={
                routeParams.vacancyId
                  ? (e: MultiselectData[], unbind) => {
                      setVacancy({ ...vacancy, labels: e });
                      setLabels(e);
                      if (unbind) {
                        changeVacationCardData({
                          ...vacationCardData,
                          labels: e,
                        });
                      }
                    }
                  : setLabels
              }
              vacancyId={routeParams.vacancyId}
            />
            <div className={cx("mt-16")}>
              <FileUploader
                className={style.CreateOrUpdateVacancyFormFieldAttach}
                files={fileUploader}
                filesLimit={1}
                label={"Загрузите файл рассылки"}
                listShape="cardCell"
                mode="attach"
                onChange={(files: any) => {
                  setFileChanges(true);
                  setFileUploader(files);
                }}
                validateFiles={async files => {
                  const ONE_MB = 1000000;
                  const fiteredFiles = files.filter(
                    f => f.name.includes(".pdf") && f.size <= ONE_MB * 5,
                  );

                  if (!fiteredFiles.length) {
                    alert(
                      "Файл может быть только PDF и не должен превышать 5МБ",
                    );
                  }

                  return fiteredFiles;
                }}
                variant="icon"
              />
            </div>
            <div className={cx("mt-16")}>
              <label>
                <div className="mb-2">
                  <>
                    Описание вакансии для шаблона-приглашения
                    <span className={"text-critical-base"}>*</span>
                    <Tooltip
                      className="ml-2"
                      content={
                        <>
                          <h3>
                            Напишите текст для п.5 в шаблон. Пример шаблона:
                          </h3>
                          <p>
                            (1), добрый день! Меня зовут (2), я представляю
                            компанию (3), (4). Сейчас у нас открыта вакансия
                            (5). Прилагаю подробное описание ниже. Интересно ли
                            вам такое предложение? <br />
                            <br />
                            Например: «Разработчика frontend на полную
                            занятость, работа полностью удаленная. Предстоит
                            заниматься развитием сайта на базе языков JS, React.
                            Мы предлагаем: оформление по трудовой, фикс+премии,
                            ДМС»
                          </p>
                        </>
                      }
                      triggerOn="hover"
                    />
                  </>
                </div>
              </label>
              <TextArea
                indent={false}
                maxLength={650}
                onChange={(e: any) => {
                  setVacancy({ ...vacancy, description: e.value });
                }}
                placeholder="Введите"
                shape="default"
                value={{ value: vacancy.description }}
                visibleCount={true}
              />
            </div>

            <h2 className="mb-0">Информация об участниках вакансии</h2>
            <div className={cx("mt-16", style.CreateOrUpdateVacancyFormField)}>
              <div>
                <Select
                  className={cx("p-0")}
                  data={ManagersData}
                  label={
                    <span>
                      Нанимающий менеджер{" "}
                      <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(manager: any) => {
                    setSelectedOptionManager(manager);
                    setVacancy({ ...vacancy, managerContacts: manager.email });
                  }}
                  shape="autocomplite"
                  value={selectedOptionManager}
                />
              </div>
              <div>
                <Input
                  label={
                    <span>
                      Электронная почта менеджера{" "}
                      <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(e: any) =>
                    setVacancy({ ...vacancy, managerContacts: e.target.value })
                  }
                  onClear={() =>
                    setVacancy({ ...vacancy, managerContacts: "" })
                  }
                  placeholder="Введите"
                  value={vacancy.managerContacts}
                />
              </div>
            </div>
            <RecruiterData setVacancy={setVacancy} vacancy={vacancy} />
            <ResearcherData setVacancy={setVacancy} vacancy={vacancy} />
          </div>
        </div>
      )}
    </>
  );
};
export default CreateOrUpdateVacancy;
