import useEnterKey from "../../../../hooks/useEnterKey";
import style from "./Modal.module.scss";
import SkeletonField from "./Skeleton/Skeleton";
import {
  Button,
  Popup,
  Select,
  Input,
  useSnackbars,
  Multiselect,
} from "@aurora/components";
import cx from "classnames";
import { DICTIONARY } from "constants/dictionary";
import {
  useGetAvailableCandidateStatusesMutation,
  useCandidateChangeStatusMutation,
  useMassCandidateChangeStatusMutation,
  usePersonStateTransitionAvailableMutation,
} from "core/api/candidate/candidate";
import {
  useVacationGetAvailableStatusesMutation,
  useVacationChangeStatusMutation,
} from "core/api/vacancy/vacancy";
import { FC, useEffect, useState } from "react";
import { snackbarFail, snackbarSuccess } from "services/snackbar.service";

interface IVacanciesContext {
  vacancy?: any;
  candidate?: any;
  clickOnClose: any;
}

const ChangeStatusPopup: FC<IVacanciesContext> = ({
  candidate,
  clickOnClose,
  vacancy,
}) => {
  const { snackbarInfo } = useSnackbars();

  const [selectedStatusItem, changeSelectedStatusItem]: any = useState(null);
  const [comment, changeComment]: any = useState("");
  const [isLoading, changeStatusLoading]: any = useState(true);
  const [statusesData, changeStatusData]: any = useState(null);
  const [selectedHistoryState, changeHistoryState]: any = useState([]);

  const [getAvailableVacationStatuses] =
    useVacationGetAvailableStatusesMutation();

  const [getAvailableCandidateStatuses] =
    useGetAvailableCandidateStatusesMutation();
  const [getPersonStateTransitionAvailable] =
    usePersonStateTransitionAvailableMutation();
  const [changeVacationStatus] = useVacationChangeStatusMutation();
  const [changeMassCandidateStatuses] = useMassCandidateChangeStatusMutation();
  const [changeCandidateStatus] = useCandidateChangeStatusMutation();

  useEffect(() => {
    if (candidate?.id) {
      getAvailableCandidateStatuses({
        candidateId: candidate.id,
        vacancyId: vacancy.id,
      }).then((result: any) => {
        changeStatusData(result.data);

        changeStatusLoading(false);
      });
      return;
    }

    if (candidate instanceof Array && candidate.length > 0) {
      getPersonStateTransitionAvailable({}).then((result: any) => {
        changeStatusData(result.data);

        changeStatusLoading(false);
      });
      return;
    }

    // Vacation statuses if didn't get any candidate params
    getAvailableVacationStatuses({ vacancyId: vacancy.id }).then(
      (result: any) => {
        changeStatusData(result.data);

        // changeSelectedStatusItem(result.data[0]);
        changeStatusLoading(false);
      },
    );
  }, [
    candidate,
    vacancy,
    getAvailableCandidateStatuses,
    getAvailableVacationStatuses,
  ]);

  useEnterKey(changeStatus);

  function showNotificationOfResult(hasError: boolean, type: string) {
    //todo: дополнительно необходимо обработать 403 ошибку "Недостаточно прав на воплнение операции"
    if (hasError) {
      const fail: any = snackbarFail(DICTIONARY.FAIL);
      snackbarInfo?.show(fail);
      return;
    }

    if (type === "mass-candidate") {
      snackbarInfo?.show(
        snackbarSuccess(DICTIONARY.CHANGE_CANDIDATES_STATUSES),
      );
      return;
    }
    if (type === "candidate") {
      snackbarInfo?.show(snackbarSuccess(DICTIONARY.CHANGE_CANDIDATE_STATUS));
      return;
    }
    // vacancy
    if (type === "vacancy") {
      snackbarInfo?.show(snackbarSuccess(DICTIONARY.CHANGE_VACANCY_STATUS));
    }
  }

  function getStateCodesFromMultiSelect() {
    return selectedHistoryState.map((state: any) => {
      return state.stateCode;
    });
  }

  async function changeStatus() {
    if (!selectedStatusItem) return;
    changeStatusLoading(true);

    if (candidate?.id) {
      const result: any = await changeCandidateStatus({
        additionalStateCodes: getStateCodesFromMultiSelect(),
        comment,
        personId: candidate.id,
        stateCode: selectedStatusItem.stateCode,
        vacancyId: vacancy.id,
      });
      clickOnClose(true);
      showNotificationOfResult(!!result?.error, "candidate");
      return;
    }

    if (candidate instanceof Array && candidate.length > 0) {
      const candidateIds: Array<any> = [
        ...candidate.map(v => ({
          personId: v,
          vacancyId: vacancy.id,
        })),
      ];
      const result: any = await changeMassCandidateStatuses({
        additionalStateCodes: getStateCodesFromMultiSelect(),
        candidateIds,
        comment,
        stateCode: selectedStatusItem.stateCode,
      });

      clickOnClose(true);
      showNotificationOfResult(!!result?.error, "mass-candidate");
      return;
    }

    const result: any = await changeVacationStatus({
      additionalStateCodes: getStateCodesFromMultiSelect(),
      comment,
      stateCode: selectedStatusItem.stateCode,
      vacancyId: vacancy.id,
    });

    clickOnClose(true);
    showNotificationOfResult(!!result?.error, "vacancy");
  }

  return (
    <>
      <Popup
        classNameContent={cx(style.modal, "p-16")}
        classNameWrapper="p-0"
        onClickCloseCross={clickOnClose}
        onClickOutside={clickOnClose}
      >
        {isLoading ? (
          <SkeletonField />
        ) : (
          <>
            <div className={"title-and-buttons-align"}>
              <h1 className={cx("my-0 text-black")}>Перевод статуса</h1>
              <div>
                <Button onClick={clickOnClose} variant="secondary">
                  Отмена
                </Button>
                <Button
                  disabled={!selectedStatusItem}
                  onClick={changeStatus}
                  variant="primary"
                >
                  Сохранить
                </Button>
              </div>
            </div>

            <div className="my-16">
              Вы можете выбрать статус для перевода и написать комментарий
            </div>

            <div className="slice-to-2">
              <div className="mb-4">
                <Select
                  className={cx("p-0")}
                  data={statusesData}
                  label="Следующий статус"
                  onChange={changeSelectedStatusItem}
                  shape="dropdown"
                  value={selectedStatusItem}
                />
              </div>
              <div className="mb-4">
                <Multiselect
                  className={cx(style.multiselectDiv, "mb-0")}
                  data={statusesData || []}
                  indent={false}
                  label="Добавить в историю"
                  onChange={e => {
                    changeHistoryState(e);
                  }}
                  placeholder="Введите имя статуса"
                  shape="select"
                  showArrow={false}
                  value={selectedHistoryState}
                />
              </div>
            </div>

            <div className="mb-4">
              <Input
                label="Комментарий"
                onChange={(e: any) => {
                  changeComment(e.target.value);
                }}
                placeholder="Введите"
                value={comment}
              />
            </div>
          </>
        )}
      </Popup>
    </>
  );
};
export default ChangeStatusPopup;
