import { ROUTE_EDIT_CANDIDATE } from "../../../../constants/routes";
import {
  candidateCustomApi,
  useCandidateDeleteMutation,
} from "../../../../core/api/candidate/candidate";
import { store } from "../../../../core/store";
import { setState } from "../../../../core/store/reducers/ContextMenuSlice";
import useErrorNotification from "../../../../hooks/useErrorNotification";
import { DocIcon } from "../../../../services/docicon.service";
import ContextMenu from "../../../BaseComponents/ContextMenu";
import NoData from "../../../BaseComponents/NoData";
import RightSidebar from "../../../BaseComponents/RightSidebar/RightSidebar";
import { SkeletonTable } from "../../../BaseComponents/SkeletonTable/SkeletonTable";
import Card from "../Card/Card";
import {
  ICandidatesListProps,
  SortDirection,
  SortDirectionField,
  SortField,
} from "../types";
import style from "./CandidatesList.module.scss";
import { Button, Indicator, Popup, useSnackbars } from "@aurora/components";
import {
  GlyphBin,
  GlyphEdit,
  GlyphMore,
  GlyphSurvey,
  SmallFall,
  SmallGrow,
} from "@aurora/icons";
import cx from "classnames";
import LinkWithVacancy from "components/BaseComponents/Popups/LinkWithVacancy/Modal";
import { DICTIONARY } from "constants/dictionary";
import { useHasAnyNewMessagesMutation } from "core/api/chat/chat";
import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { snackbarSuccess } from "services/snackbar.service";

const initSortDirection: SortDirectionField = {
  fullName: undefined,
  resumeUploadDate: undefined,
};

const CandidatesList: FC<ICandidatesListProps> = ({
  candidatesData,
  isCandidatesLoading,
  showStatuses = true,
  updateCandidateData,
}: any) => {
  const { snackbarInfo } = useSnackbars();
  const navigate = useNavigate();
  const errorNotification = useErrorNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );

  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [candidateDeleteId, setCandidateDeleteId] = useState<string>("");
  const [candidateSelected, setCandidateSelected] = useState<any>();
  const [hasAnyMessages, setHasAnyMessages] = useState<any>({});
  const [showConfirm, changeShowConfirm] = useState(false);
  const [isShowVacancyPopup, setShowVacancyPopup] = useState(false);
  const [bindVacancyCallback, setCallbackOfBindVacancy]: any = useState();
  const [sortDirection, setSortDirection] =
    useState<SortDirectionField>(initSortDirection);
  const [contextMenuList, setContextMenuList]: any[] = useState([]);

  const [
    updateCardCallbackAfterChangeStatus,
    setNewCallbackForUpdateCard,
  ]: any = useState(null);

  const [deleteCandidate] = useCandidateDeleteMutation();
  const [hasAnyNewMessages] = useHasAnyNewMessagesMutation();

  function openFileInNewTab(candidateId: string) {
    const link = document.createElement("a");
    candidateCustomApi.downloadResume(candidateId).then(file => {
      if (!file.data) return;
      link.href = URL.createObjectURL(file.data);
      link.target = "_blank";
      link.dispatchEvent(new MouseEvent("click"));
    });
  }

  async function loadStatusOfMessages() {
    const messages: any = await hasAnyNewMessages();
    if (messages.error) return;
    const mess = messages?.data.reduce((acc: any, item: string) => {
      acc[item] = true;
      return acc;
    }, {});
    setHasAnyMessages(mess);
  }

  function setSelectedItem(item: any) {
    setCandidateSelected(item);
  }

  function getActiveSelectedItemById(id: string) {
    const candidateItem = candidatesData?.items.filter((item: any) => {
      return item.id === id;
    })[0];
    if (!candidateItem) return { id };

    return {
      ...candidateItem,
      hasNewMessages: hasAnyMessages[candidateItem.id],
    };
  }

  useEffect(() => {
    if (searchParams.get("candidateId")) {
      setCandidateSelected({ id: searchParams.get("candidateId") });
      setIsRightSidebarOpen(true);
    }
    loadStatusOfMessages();
    const interval = setInterval(loadStatusOfMessages, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!isCandidatesLoading) {
      updateCandidateData(sortDirection);
    }
  }, [sortDirection]);

  const header = [
    {
      element: (
        <div>
          ФИО <br /> кандидата
        </div>
      ),
      item: "fullName",
    },
    { element: <div>Телефон</div>, item: "phone" },
    {
      element: (
        <div>
          Электронная <br /> почта
        </div>
      ),
      item: "email",
    },
    { element: <div>Файл</div>, item: "file" },
    { element: <div>Дата резюме</div>, item: "resumeUploadDate" },
    { element: "", item: "actionBtn" },
  ];

  const tableHead = header.map((item: any) => {
    const sortIcon = (
      <div
        className={cx(
          style.CandidatesTable__icon,
          style.CandidatesTable__icon__align_left,
        )}
      >
        {sortDirection[item.item as SortField] === "asc" && (
          <SmallFall data-testid="sort-down" />
        )}
        {sortDirection[item.item as SortField] === "desc" && (
          <SmallGrow data-testid="sort-up" />
        )}
      </div>
    );
    function onSort(item: SortField) {
      setSortDirection(prevState => {
        const currentDirection = prevState[item];
        let newDirection: SortDirection | undefined;
        if (currentDirection === undefined) {
          newDirection = "asc";
        } else if (currentDirection === "asc") {
          newDirection = "desc";
        } else {
          newDirection = undefined;
        }
        return { ...initSortDirection, [item]: newDirection };
      });
    }
    return (
      <th
        key={item.item}
        className={cx(
          item.item in sortDirection && style.CandidatesTable__point,
        )}
        onClick={() => {
          if (item.item in sortDirection) onSort(item.item);
        }}
      >
        {item.item in sortDirection && sortIcon}
        {item.element}
      </th>
    );
  });

  const getList = (item: any): any[] => {
    return [
      {
        icon: <GlyphEdit className="text-accent" />,
        name: "Редактировать",
        onClick: () => {
          navigate(`${ROUTE_EDIT_CANDIDATE}/${item.id}`);
        },
      },
      {
        icon: <GlyphSurvey className="text-accent" />,
        name: "Связи с вакансиями",
        onClick: () => {
          setSelectedItem(item);
          setShowVacancyPopup(true);
        },
      },
      {
        icon: <GlyphBin className="text-accent" />,
        name: "Удалить",
        onClick: () => {
          setCandidateDeleteId(item.id);
          changeShowConfirm(true);
        },
      },
    ];
  };
  return (
    <div className={style.CandidatesListContainer}>
      {candidatesData?.total <= 0 ? (
        <NoData />
      ) : (
        <table className={style.CandidatesTable}>
          <colgroup>
            <col width={"24.6%"} />
            <col width={"20.6%"} />
            <col width={"24.6%"} />
            <col width={"6.7%"} />
            <col width={"17.9%"} />
            <col width={"75px"} />
          </colgroup>
          <thead>
            <tr>{tableHead.map((element: any) => element)}</tr>
          </thead>
          <tbody>
            {isCandidatesLoading ? (
              <SkeletonTable rows={10} />
            ) : (
              candidatesData?.items.map((item: any) => {
                return (
                  <tr
                    key={item.id}
                    className="border-top"
                    onClick={() => {
                      setIsRightSidebarOpen(true);
                      setSelectedItem(item);
                    }}
                  >
                    <td className="truncate-cell" title={item.fullName}>
                      {hasAnyMessages[item.id] ? (
                        <span
                          className={cx("mr-4", style.indicatorNewMessages)}
                        >
                          <Indicator shape="point" variant="blueActive" />
                        </span>
                      ) : null}
                      {item.fullName}
                    </td>
                    <td className="truncate-cell" title={item.phone}>
                      {item.phone || "-"}
                    </td>
                    <td className="truncate-cell" title={item.email}>
                      {item.email || "-"}
                    </td>
                    <td>
                      <>
                        {item.resumeUploaded ? (
                          <a
                            className={"link"}
                            onClick={(e?: React.MouseEvent) => {
                              e?.stopPropagation();
                              openFileInNewTab(item.id);
                            }}
                          >
                            <DocIcon fileName={item.resumeFileName} />
                          </a>
                        ) : (
                          <span>&nbsp;-</span>
                        )}
                      </>
                    </td>
                    <td className="truncate-cell">
                      {item.resumeUploaded
                        ? new Date(item.resumeUploadDate).toLocaleDateString()
                        : "-"}
                    </td>
                    <td
                      className={cx(style.Icons, "text-right", "p-4")}
                      style={{ position: "relative" }}
                    >
                      {!isManagerOrWatcher && (
                        <Button
                          className={cx(
                            style.AdditionallyMenuButton,
                            style.CreateButton,
                            "bg-content-2",
                            "text-black",
                            "p-6",
                          )}
                          iconLeft={<GlyphMore />}
                          onClick={(event: any) => {
                            event.preventDefault();
                            event.stopPropagation();
                            const list = getList(item);
                            setContextMenuList(list);
                            const rect = event.target
                              .closest("td")
                              .getBoundingClientRect();

                            store.dispatch(
                              setState({
                                visible: true,
                                x: rect.width + rect.x,
                                y: rect.y - rect.height,
                              }),
                            );
                          }}
                          shape={"default"}
                          variant={"primary"}
                        >
                          {""}
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      )}

      {isShowVacancyPopup ? (
        <LinkWithVacancy
          candidate={getActiveSelectedItemById(candidateSelected.id)}
          clickOnClose={() => {
            setShowVacancyPopup(false);
            updateCandidateData();
            if (bindVacancyCallback) {
              bindVacancyCallback();
              setCallbackOfBindVacancy(null);
            }
          }}
          type="candidate"
        />
      ) : null}

      <ContextMenu list={contextMenuList} />

      {!showStatuses &&
        ReactDOM.createPortal(
          <>
            <RightSidebar
              onClose={() => {
                setSearchParams({});
                setIsRightSidebarOpen(false);
              }}
              show={isRightSidebarOpen}
            >
              {isRightSidebarOpen ? (
                <Card
                  candidate={getActiveSelectedItemById(candidateSelected.id)}
                  onCloseBindCandidateModal={(callback: any) => {
                    setCallbackOfBindVacancy(callback);
                  }}
                  openBindCandidateModal={setShowVacancyPopup}
                  openUpdateStatus={(callbackStatusUpdate: any) => {
                    setNewCallbackForUpdateCard(callbackStatusUpdate);
                  }}
                />
              ) : null}
            </RightSidebar>
          </>,
          document.body,
        )}
      {showConfirm ? (
        <Popup
          classNameContent={cx(style.deleteCandidateContent)}
          classNameWrapper={cx(style.deleteCandidateWrapper)}
          onClickCloseCross={() => {
            changeShowConfirm(false);
          }}
          onClickOutside={() => {
            changeShowConfirm(false);
          }}
        >
          <div className="p-12">
            <h1 className="text-accent my-0">Удалить карточку кандидата?</h1>
            <p className="my-12">
              Вы уверены, что хотите удалить карточку кандидата? После этого ее
              нельзя будет восстановить.
            </p>
            <div className={cx(style.deleteCandidateButtons)}>
              <Button
                className="mr-4"
                onClick={() => {
                  changeShowConfirm(false);
                }}
                variant="secondary"
              >
                Отмена
              </Button>
              <Button
                onClick={async () => {
                  const resultOfDelete: any = await deleteCandidate(
                    candidateDeleteId,
                  );

                  if (resultOfDelete.error) {
                    errorNotification(resultOfDelete.error);
                  } else {
                    const candidate = candidatesData?.items.filter(
                      (candidate: any) => {
                        return candidate.id === candidateDeleteId;
                      },
                    )[0];

                    const success: any = snackbarSuccess(
                      DICTIONARY.DELETE_CANDIDATE(candidate.fullName),
                    );
                    snackbarInfo?.show(success);
                  }

                  updateCandidateData();
                  changeShowConfirm(false);
                }}
                variant="sell"
              >
                Удалить
              </Button>
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default CandidatesList;
