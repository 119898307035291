import { ROUTE_VACANCIES } from "../../../constants/routes";
import { useAddNewLabelMutation } from "../../../core/api/base/other";
import {
  candidateCustomApi,
  useBindLabelsToPersonMutation,
  useCandidateCardQuery,
  useCandidateCreateMutation,
  useCandidateDeleteResumeMutation,
  useFileInfoMutation,
  usePersonBindToVacancyMutation,
  useUpdateCandidateMutation,
  useUploadResumeMutation,
} from "../../../core/api/candidate/candidate";
import useErrorNotification from "../../../hooks/useErrorNotification";
import { isEqual } from "../../../services/object.service";
import Label from "../../BaseComponents/Label";
import Skeleton from "../CreateOrUpdateVacancy/Skeleton/Skeleton";
import style from "./EditCandidate.module.scss";
import {
  Button,
  FileUploader,
  Input,
  MultiselectData,
  useSnackbars,
} from "@aurora/components";
import cx from "classnames";
import { DICTIONARY } from "constants/dictionary";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  checkEmail,
  checkPhone,
  isValidUsername,
  validateName,
  weakEqual,
} from "services/check.service";
import { generateRandomIdByLength } from "services/generate.service";
import { snackbarFail, snackbarSuccess } from "services/snackbar.service";
import { validate as isValidUUID } from "uuid";

interface init {
  email: string;
  firstName: string;
  labels: MultiselectData[];
  lastName: string;
  middleName: string;
  phone: string;
}
const initFormState: init = {
  email: "",
  firstName: "",
  labels: [],
  lastName: "",
  middleName: "",
  phone: "",
};

const EditCandidate: FC = () => {
  const { snackbarInfo } = useSnackbars();
  const navigate = useNavigate();
  const routeParams = useParams();
  const errorNotification = useErrorNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fileResume, setFileResume] = useState<any>([]);
  const [rndCandidateValue, changeRndValue]: any = useState();
  const [isValidFields, changeIsValidFields]: any = useState({
    email: true,
    phone: true,
    telegram: true,
  });
  const [infoResume, setInfoResume]: any = useState(undefined);
  const [newLabels, setLabels] = useState<MultiselectData[]>([]);
  const [candidate, setCandidate]: any = useState(initFormState);
  const [isLoading, changeLoading] = useState(false);
  const [hasAnyChanges, changeHasAnyChanges] = useState(false);
  const [hasLabelsChanges, changeHasLabelsChanges] = useState(false);

  function isDisabledUpdateButton() {
    if (candidate.email && !isValidFields.email) {
      return true;
    }

    if (candidate.phone && !isValidFields.phone) {
      return true;
    }
    if (candidate.telegramName && !isValidFields.telegram) return true;

    return (
      (!candidate.email && !candidate.phone) ||
      !candidate.firstName ||
      !candidate.lastName ||
      (!hasAnyChanges && !hasLabelsChanges)
    );
  }

  const { data: candidateData, isLoading: isLoadingCandidateData } =
    useCandidateCardQuery(
      { candidateId: routeParams.personId, rnd: rndCandidateValue },
      { refetchOnMountOrArgChange: true, skip: !routeParams.personId },
    );

  const [updateCandidate] = useUpdateCandidateMutation();
  const [createCandidate] = useCandidateCreateMutation();
  const [bindPersonToVacancy] = usePersonBindToVacancyMutation();
  const [uploadResume] = useUploadResumeMutation();
  const [deleteResume] = useCandidateDeleteResumeMutation();
  const [addNewLabel] = useAddNewLabelMutation();
  const [bindLabelsToCandidate] = useBindLabelsToPersonMutation();

  useEffect(() => {
    setCandidate({ ...candidate, labels: newLabels });
  }, [newLabels]);
  const [getInfoResume] = useFileInfoMutation();

  useEffect(() => {
    if (!routeParams.personId) {
      changeHasAnyChanges(true);
      return;
    }
    if (candidateData?.resumeUploaded) {
      getInfoResume(routeParams.personId).then((fileInfo: any) => {
        setInfoResume(fileInfo.data);
        candidateCustomApi
          .downloadResume(candidateData?.id)
          .then((file: any) => {
            const res = new File([file.data], fileInfo.data.fileName, {
              type: fileInfo.data.contentType,
            });
            setFileResume([res]);
          });
      });
    }

    if (!isLoadingCandidateData) {
      checkFields("email", candidateData.email);
      checkFields("phone", candidateData.phone);
      if (candidateData.telegramName)
        checkFields("telegram", candidateData.telegramName);

      setCandidate({
        email: candidateData.email,
        firstName: candidateData.firstName,
        labels: candidateData.labels,
        lastName: candidateData.lastName,
        middleName: candidateData.middleName,
        phone: candidateData.phone,
        telegramName: candidateData.telegramName,
      });
    }
  }, [candidateData]);

  useEffect(() => {
    const isUpdateCard = !!routeParams.personId;
    if (!isUpdateCard || isLoadingCandidateData) return;
    let hasChanges = false;
    let labelChanges = false;

    Object.keys(candidate).forEach((key: string) => {
      if (key !== "labels" && !weakEqual(candidateData[key], candidate[key]))
        hasChanges = true;
      if (
        key === "labels" &&
        !isEqual(candidateData[key], candidate[key], true)
      )
        labelChanges = true;
    });
    changeHasLabelsChanges(labelChanges);
    changeHasAnyChanges(hasChanges);
  }, [candidate]);

  function workWithLabels(
    candidateLabels: MultiselectData[],
    personId?: string,
  ) {
    const candidateId = personId ?? "";
    const newLabels: MultiselectData[] = [
      ...candidateLabels.filter(
        (label: MultiselectData) => !isValidUUID(label.id),
      ),
    ];
    Promise.all([
      ...candidateLabels
        .filter((label: MultiselectData) => isValidUUID(label.id))
        .map((label: MultiselectData) => addNewLabel(label)),
    ]).then(result => {
      result.map((d: any) => {
        if (!d.data) return;
        newLabels.push({ ...d.data });
      });
      bindLabels([...newLabels], candidateId)
        .then((result: any) => {
          if (result.data) {
            setCandidate({ ...candidate, labels: result.data.labels });
            snackbarInfo?.show(snackbarSuccess(DICTIONARY.LABEL_ADD_TO_CARD));
          }
        })
        .catch(e => {
          // если ошибка RangeError то пришел rejected, никак на это не реагируем
          if (!(e instanceof RangeError)) {
            console.log("Error", e);
            const fail: any = snackbarFail(DICTIONARY.FAIL);
            snackbarInfo?.show(fail);
          }
        })
        .finally(() => {
          changeHasLabelsChanges(false);
          changeLoading(false);
          changeRndValue(generateRandomIdByLength(10));
        });
    });
  }

  function checkFields(type: string, value: string) {
    let isValid = false;
    switch (type) {
      case "email":
        {
          isValid = checkEmail(value);
        }
        break;
      case "phone":
        {
          isValid = checkPhone(value);
        }
        break;
      case "telegram": {
        isValid = isValidUsername(value);
      }
    }
    changeIsValidFields({
      ...isValidFields,
      [type]: isValid,
    });
  }

  async function updateCandidateFunc() {
    if (isDisabledUpdateButton()) return;
    changeLoading(true);
    let candidateResult: any = null;
    const isUpdateCard = !!routeParams.personId;
    if (hasLabelsChanges && isUpdateCard) {
      workWithLabels(candidate.labels, routeParams.personId);
      // если нет больше никаких изменений кроме меток, не пускаем дальше
      if (!hasAnyChanges) return;
    }
    if (isUpdateCard) {
      // Update existing candidate
      await updateCandidate({
        ...candidate,
        id: candidateData.id,
      }).then(res => {
        candidateResult = res;
      });
    } else {
      // Create new candidate
      await createCandidate({
        ...candidate,
      }).then((result: any) => {
        candidateResult = result;
        if (!candidateResult.error) {
          workWithLabels(newLabels, candidateResult.data.id);
        }
      });
    }

    if (candidateResult.error) {
      errorNotification(candidateResult.error);
      changeLoading(false);
      return;
    }

    if (fileResume.length) {
      const formData = new FormData();
      formData.append("document", fileResume[0]);
      const resume: any = await uploadResume({
        document: formData,
        personId: candidateResult.data.id,
      });

      if (resume?.error) {
        const fail: any = snackbarFail(DICTIONARY.FAIL_ADDED_FILE_TO_CANDIDATE);
        snackbarInfo?.show(fail);
      }
    }

    if (isUpdateCard && !fileResume.length && candidateData.resumeUploaded) {
      await deleteResume({ personId: candidateData.id });
    }
    const vacancyId = searchParams.get("vacancyId");
    if (vacancyId) {
      const resultOfBind: any = await bindPersonToVacancy({
        personId: candidateResult.data.id,
        vacancyId: vacancyId,
      });

      if (resultOfBind?.data) {
        snackbarInfo?.show(
          snackbarSuccess(
            DICTIONARY.BIND_CANDIDATE_VACANCY(resultOfBind?.data.fullName),
          ),
        );
        navigate(`${ROUTE_VACANCIES}?vacancyId=${vacancyId}`);
        return;
      } else {
        const fail: any = snackbarFail(DICTIONARY.FAIL_BIND_TO_CANDIDATE);
        snackbarInfo?.show(fail);
      }
    }

    snackbarInfo?.show(
      snackbarSuccess(
        DICTIONARY.SUCCESS_CANDIDATE(
          `${candidateResult?.data.firstName} ${candidateResult?.data.middleName} ${candidateResult?.data.lastName}`,
          isUpdateCard,
        ),
      ),
    );

    if (!isUpdateCard && !vacancyId) {
      // navigate(`${ROUTE_EDIT_CANDIDATE}/${candidateResult.data.id}`);
      navigate(-1);
      return;
    }
    changeLoading(false);
    changeHasAnyChanges(false);
    changeRndValue(generateRandomIdByLength(10));
  }

  async function bindLabels(dataLabels: MultiselectData[], personId: string) {
    // если массив лейблов пустой отменяем формирование запроса
    if (dataLabels.length === 0)
      return Promise.reject(new RangeError("rejected"));
    // для всех меток id которых нет в initLabels сделаем привязку
    return await bindLabelsToCandidate({
      labelIds: !routeParams.personId
        ? dataLabels.map(label => label.id)
        : dataLabels
            .filter(
              label =>
                !candidateData.labels.some(
                  (value: MultiselectData) => label.id === value.id,
                ),
            )
            .map(label => label.id),
      personId,
    });
  }

  function onLabelsChange(labelsChanges: MultiselectData[], unbind?: boolean) {
    if (routeParams.personId) {
      setCandidate({ ...candidate, labels: labelsChanges });
      if (unbind) {
        changeRndValue(generateRandomIdByLength(10));
      }
    } else {
      setLabels(labelsChanges);
    }
  }

  return (
    <>
      {isLoadingCandidateData || isLoading ? (
        <Skeleton />
      ) : (
        <div className={style.EditCandidate}>
          <div className={style.EditCandidateHeader}>
            <h1 className={cx("my-0 text-black")}>
              {routeParams.personId
                ? "Редактирование кандидата"
                : "Новый кандидат"}
            </h1>
            <div className={style.EditCandidateHeaderButtons}>
              <Button
                className={style.ButtonStyles}
                onClick={() => {
                  navigate(-1);
                }}
                variant="secondary"
              >
                Отмена
              </Button>

              <Button
                className={style.ButtonStyles}
                disabled={isDisabledUpdateButton()}
                onClick={updateCandidateFunc}
                variant="primary"
              >
                Сохранить
              </Button>
            </div>
          </div>
          <div className={style.EditCandidateForm}>
            <div className={style.EditCandidateFormFio}>
              <div>
                {" "}
                <Input
                  label={
                    <span>
                      Фамилия <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(e: any) => {
                    setCandidate({
                      ...candidate,
                      lastName: validateName(e.target.value),
                    });
                  }}
                  placeholder="Введите"
                  value={candidate.lastName}
                />
              </div>
              <div>
                {" "}
                <Input
                  label={
                    <span>
                      Имя <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(e: any) => {
                    setCandidate({
                      ...candidate,
                      firstName: validateName(e.target.value),
                    });
                  }}
                  placeholder="Введите"
                  value={candidate.firstName}
                />
              </div>
              <div>
                {" "}
                <Input
                  label={"Отчество"}
                  onChange={(e: any) => {
                    setCandidate({
                      ...candidate,
                      middleName: validateName(e.target.value),
                    });
                  }}
                  placeholder="Введите"
                  value={candidate.middleName}
                />
              </div>
            </div>
            <div
              className={cx(
                "mt-8 pt-8",
                style.EditCandidateFormContact,
                "border-top",
              )}
            >
              <div>
                {" "}
                <Input
                  description={
                    isValidFields.phone ? null : DICTIONARY.NOT_VALID_PHONE
                  }
                  label={
                    <span>
                      Телефон <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  mask="9 (999) 999-99-99"
                  onChange={(e: any) => {
                    const phone = e.target.value;
                    checkFields("phone", phone);
                    setCandidate({
                      ...candidate,
                      phone: phone,
                    });
                  }}
                  placeholder="Введите"
                  status={isValidFields.phone ? null : "error"}
                  type={"tel"}
                  value={candidate.phone}
                />
              </div>
              <div>
                {" "}
                <Input
                  description={
                    isValidFields.email ? null : DICTIONARY.NOT_VALID_EMAIL
                  }
                  label={
                    <span>
                      E-mail <span className={"text-critical-base"}>*</span>
                    </span>
                  }
                  onChange={(e: any) => {
                    const email = e.target.value || null;
                    checkFields("email", email);
                    setCandidate({ ...candidate, email: email });
                  }}
                  placeholder="Введите"
                  status={isValidFields.email ? null : "error"}
                  value={candidate.email}
                />
              </div>
              <div>
                {" "}
                <Input
                  description={
                    isValidFields.telegram
                      ? null
                      : DICTIONARY.NOT_VALID_TELEGRAM
                  }
                  label={<span>Ник в Telegram</span>}
                  onChange={(e: any) => {
                    const telegramName = e.target.value || null;
                    checkFields("telegram", telegramName);
                    setCandidate({ ...candidate, telegramName: telegramName });
                  }}
                  placeholder="Введите"
                  status={isValidFields.telegram ? null : "error"}
                  value={candidate.telegramName}
                />
              </div>
            </div>{" "}
            <Label
              entity={"person"}
              initLabels={
                routeParams.personId ? candidateData?.labels || [] : newLabels
              }
              needConfirmed={!!routeParams.personId}
              needSaveBt={false}
              onLabelsChange={onLabelsChange}
              personId={routeParams.personId}
            />
            <div>
              <FileUploader
                className={style.CreateOrUpdateVacancyFormFieldAttach}
                files={fileResume}
                filesLimit={1}
                label={"Файл резюме"}
                listShape="cardCell"
                mode="attach"
                onChange={(files: any) => {
                  changeHasAnyChanges(true);
                  setFileResume(files);
                }}
                validateFiles={async files => {
                  const ONE_MB = 1000000;
                  const filteredFiles = files.filter(
                    f =>
                      (f.name.includes(".pdf") ||
                        f.name.includes(".doc") ||
                        f.name.includes(".docx") ||
                        f.name.includes(".rtf")) &&
                      f.size <= ONE_MB * 5,
                  );

                  if (!filteredFiles.length) {
                    alert(
                      "Файл может быть только PDF, DOC, DOCX, RTF и не должен превышать 5МБ",
                    );
                  }

                  return filteredFiles;
                }}
                variant="button"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCandidate;
