import {
  candidateCustomApi,
  useLazyCandidatesListQuery,
} from "../../../../../core/api/candidate/candidate";
import { DocIcon } from "../../../../../services/docicon.service";
import { camelCaseToUnderscore } from "../../../../../services/string.service";
import CandidateBadge from "../../../../BaseComponents/CandidateBadge";
import NoData from "../../../../BaseComponents/NoData";
import ChangeStatusPopup from "../../../../BaseComponents/Popups/VacancyChangeStatus/Modal";
import { SkeletonTable } from "../../../../BaseComponents/SkeletonTable/SkeletonTable";
import FilterBlock from "../../../Candidates/FilterBlock/FilterBlock";
import {
  SortDirectionField,
  SortDirectionUS,
  SortField,
  SortMapping,
} from "../../../Candidates/types";
import style from "./CandidatesList.module.scss";
import { Button, Checkbox, Popup, useSnackbars } from "@aurora/components";
import { GlyphBin, GlyphSwap, SmallFall, SmallGrow } from "@aurora/icons";
import cx from "classnames";
import DeleteCandidateFromVacancy from "components/BaseComponents/Popups/DeleteCandidateFromVacancy/Modal";
import { DICTIONARY } from "constants/dictionary";
import { ROUTE_CANDIDATE } from "constants/routes";
import { useVacationSendResumeMutation } from "core/api/vacancy/vacancy";
import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { snackbarFail, snackbarSuccess } from "services/snackbar.service";

interface Props {
  vacancy?: any;
  candidatesData?: any;
  isCandidatesLoading?: boolean;
  updateCandidateData?: any;
  updateQueryParamsCandidate: any;
}

const getDefaultSelecteFilter = () => {
  return {
    changeStatus: [],
    sendResume: [],
  };
};

const initSortDirection: SortDirectionField = {
  fullName: undefined,
  resumeUploadDate: undefined,
};

const sortMapping: SortMapping = {
  fullName: "FULL_NAME",
  resumeUploadDate: "RESUME_UPLOAD_DATE",
};

const CandidatesList: FC<Props> = ({
  candidatesData,
  isCandidatesLoading,
  updateCandidateData,
  updateQueryParamsCandidate,
  vacancy,
}: any) => {
  const isManagerOrWatcher = useSelector(
    (store: any) => store.userDataSlice.isManagerOrWatcher,
  );
  const FilterBlockID = document.getElementById("RightSideBarFilterBlock");
  const { snackbarInfo } = useSnackbars();
  const [isResendResumeBtnDisabled, changeResendResumeDisabledBtn] =
    useState(true);
  const [isMassStatusesBtnDisabled, changeMassStatusesDisabledBtn] =
    useState(true);
  const [loadingFilter, changeIsLoadingFilter] = useState(false);
  const [isLoadingModal, changeIsLoadingModal] = useState(false);
  const [selectedItemsFilter, changeSelectedItemsFilter]: any = useState(
    getDefaultSelecteFilter(),
  );

  const [statusCandidateId, setStatusCandidateId] = useState<string | null>();
  const [isModalStatusEditOpen, setIsModalStatusEditOpen] = useState(false);
  const [isModalMassStatusEditOpen, setIsMassModalStatusEditOpen] =
    useState(false);
  const [selectedItems, setSelectedItems]: any = useState({});
  const [selectedItemsRessend, setSelectedItemsRessend]: any = useState([]);
  const [isSelectAll, changeSelectAll]: any = useState(false);
  const [btnDisabledResend, changeBtnDisabledResend]: any = useState(true);

  const [showPopupReplyMessage, changeShowPopupReplyMessage]: any =
    useState(false);
  const [isModalDeleteCandidateOpen, setIsModalDeleteCandidateOpen] =
    useState(false);

  const [sortDirection, setSortDirection] =
    useState<SortDirectionField>(initSortDirection);

  const [sendResume] = useVacationSendResumeMutation();
  const [loadCandidatesData] = useLazyCandidatesListQuery();

  useEffect(() => {
    if (isCandidatesLoading) return;
    isAllItemsSelected();
  }, [isCandidatesLoading, selectedItems]);

  useEffect(() => {
    changeBtnDisabledResend(true);
    selectedItemsRessend.map((item: any) => {
      if (selectedItems[item.id]) {
        changeBtnDisabledResend(false);
      }
    });
  }, [selectedItems, selectedItemsRessend]);

  useEffect(() => {
    if (!isCandidatesLoading) {
      const sortFieldValue = Object.keys(sortDirection).find(
        v => sortDirection[v as keyof SortDirectionField] !== undefined,
      );
      const sortFieldValueUS = sortMapping[sortFieldValue as keyof SortMapping];
      const sortOrderValue = camelCaseToUnderscore(
        sortDirection[sortFieldValue as keyof SortDirectionField],
      ) as SortDirectionUS;
      const newQueryParams = {
        sortField: sortFieldValueUS,
        sortOrder: sortOrderValue,
      };
      updateQueryParamsCandidate((prev: any) => ({
        ...prev,
        ...newQueryParams,
      }));
    }
  }, [sortDirection]);

  function clickOnCloseReplyMessage() {
    changeShowPopupReplyMessage(false);
    clearAllChecked();
    setSelectedItemsRessend([]);
    changeResendResumeDisabledBtn(false);
    changeIsLoadingFilter(false);
  }

  function clearAllChecked() {
    setSelectedItems({});

    changeSelectedItemsFilter(getDefaultSelecteFilter());
    changeResendResumeDisabledBtn(true);
    changeMassStatusesDisabledBtn(true);
  }

  function openFileInNewTab(candidateId: string) {
    const link = document.createElement("a");
    candidateCustomApi.downloadResume(candidateId).then(file => {
      if (!file.data) return;
      link.href = URL.createObjectURL(file.data);
      link.target = "_blank";
      link.dispatchEvent(new MouseEvent("click"));
    });
  }

  function isAllItemsSelected() {
    let isSelectedAll = true;

    candidatesData?.items.forEach((candidate: any) => {
      if (!selectedItems[candidate.id]) isSelectedAll = false;
    });

    changeSelectAll(isSelectedAll);
  }

  async function sendResumeFn() {
    const result: any = await sendResume({
      personIds: getActiveManagers("sendResume"),
      vacancyId: vacancy.id,
    });
    changeIsLoadingFilter(false);
    changeIsLoadingModal(false);

    if (result.error) {
      const fail: any = snackbarFail(DICTIONARY.FAIL_RESUME_SEND);
      snackbarInfo?.show(fail);
      changeResendResumeDisabledBtn(false);
    } else {
      const success: any = snackbarSuccess(DICTIONARY.SUCCESS_RESUME_SEND);
      clearAllChecked();
      snackbarInfo?.show(success);
    }

    changeShowPopupReplyMessage(false);
  }

  function getActiveManagers(type: string | undefined = "") {
    if (type && selectedItemsFilter[type]) {
      return selectedItemsFilter[type];
    }

    return Object.keys(selectedItems).filter(managerId => {
      return selectedItems[managerId];
    });
  }

  function selectedHandle(item: any, isSelected: boolean) {
    changeSelectedItemsFilter((old: any) => {
      const nValues = Object.assign({}, old);

      if (isSelected) {
        if (item.resumeUploaded) {
          nValues.sendResume.push(item.id);
        }
        nValues.changeStatus.push(item.id);
      }
      if (!isSelected) {
        if (item.resumeUploaded) {
          nValues.sendResume = nValues.sendResume.filter(
            (personId: string) => personId !== item.id,
          );
        }
        nValues.changeStatus = nValues.changeStatus.filter(
          (personId: string) => personId !== item.id,
        );
      }

      changeResendResumeDisabledBtn(nValues.sendResume.length === 0);
      changeMassStatusesDisabledBtn(nValues.changeStatus.length === 0);

      return nValues;
    });
  }
  const header = [
    {
      element: (
        <Checkbox
          checked={isSelectAll}
          onChange={() => {
            setSelectedItems((oldValues: any) => {
              const isSelected = !isSelectAll;
              const nValues = Object.assign({}, oldValues);
              candidatesData?.items.forEach((item: any) => {
                if (nValues[item.id] === isSelected) return;
                nValues[item.id] = isSelected;
                selectedHandle(item, isSelected);
              });
              return nValues;
            });
          }}
        />
      ),
      item: "checkboxAction",
    },
    {
      element: (
        <div>
          ФИО <br /> кандидата
        </div>
      ),
      item: "fullName",
    },
    { element: <div>Телефон</div>, item: "phone" },
    {
      element: (
        <div>
          Электронная <br /> почта
        </div>
      ),
      item: "email",
    },
    { element: <div>Файл</div>, item: "file" },
    { element: <div>Дата резюме</div>, item: "resumeUploadDate" },
    { element: <div>Текущий статус</div>, item: "status" },
    { element: "", item: "actionBtn" },
  ];

  const tableHead = header.map((item: any) => {
    const sortIcon = (
      <div
        className={cx(
          style.CandidatesTable__icon,
          style.CandidatesTable__icon__align_left,
        )}
      >
        {sortDirection[item.item as SortField] === "asc" && (
          <SmallFall data-testid="sort-down" />
        )}
        {sortDirection[item.item as SortField] === "desc" && (
          <SmallGrow data-testid="sort-up" />
        )}
      </div>
    );
    function onSort(item: SortField) {
      if (!sortDirection[item])
        setSortDirection({ ...initSortDirection, [item]: "asc" });
      if (sortDirection[item] === "asc")
        setSortDirection({ ...initSortDirection, [item]: "desc" });
      if (sortDirection[item] === "desc")
        setSortDirection({ ...initSortDirection });
    }
    return (
      <th
        key={item.item}
        className={cx(
          item.item in sortDirection && style.CandidatesTable__point,
        )}
        onClick={() => {
          if (item.item in sortDirection) onSort(item.item);
        }}
      >
        {item.item in sortDirection && sortIcon}
        {item.element}
      </th>
    );
  });

  return (
    <div className={style.CandidatesListContainer}>
      <FilterBlock
        updateCandidateData={updateQueryParamsCandidate}
        vacancyId={vacancy.id}
      />
      {candidatesData?.total <= 0 ? (
        <NoData />
      ) : (
        <table className={style.CandidatesTable}>
          <colgroup>
            <col width={"6%"} />
            <col width={"18.2%"} />
            <col width={"19.2%"} />
            <col width={"18.5%"} />
            <col width={"6.8%"} />
            <col width={"11.5%"} />
            <col className={"pr-4"} width={"13.8%"} />
            <col className={"px-0"} width={"150px"} />
          </colgroup>
          <thead>
            <tr>{tableHead.map((element: any) => element)}</tr>
          </thead>
          <tbody>
            {isCandidatesLoading ? (
              <SkeletonTable rows={10} />
            ) : (
              candidatesData?.items.map((item: any) => {
                return (
                  <tr
                    key={item.id}
                    onClick={() => {
                      window.open(
                        `${ROUTE_CANDIDATE}/?candidateId=${item.id}`,
                        "_blank",
                        "noreferrer",
                      );
                    }}
                  >
                    <td
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setSelectedItems((old: any) => {
                          const isSelected = !selectedItems[item.id];
                          const nValues = {
                            ...old,
                            [item.id]: !selectedItems[item.id],
                          };

                          selectedHandle(item, isSelected);
                          return nValues;
                        });
                      }}
                    >
                      <Checkbox checked={selectedItems[item.id]} />
                    </td>
                    <td className="truncate-cell" title={item.fullName}>
                      {item.fullName}
                    </td>
                    <td className="truncate-cell" title={item.phone}>
                      {item.phone || "-"}
                    </td>
                    <td className="truncate-cell" title={item.email}>
                      {item.email || "-"}
                    </td>
                    <td>
                      {item.resumeUploaded ? (
                        <a
                          className={"link"}
                          onClick={(e?: React.MouseEvent) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            openFileInNewTab(item.id);
                          }}
                        >
                          <DocIcon fileName={item.resumeFileName} />
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="truncate-cell">
                      {item.resumeUploaded
                        ? new Date(item.resumeUploadDate).toLocaleDateString()
                        : "-"}
                    </td>
                    <td className="truncate-cell">
                      {item.vacancies &&
                        item.vacancies.map((value: any, idx: number) => {
                          if (value.vacancyId === vacancy.id) {
                            return (
                              <CandidateBadge
                                key={idx}
                                badge={{
                                  stateCategoryCode:
                                    value.candidateStateCategoryCode,
                                  stateTitle: value.candidateStateTitle,
                                }}
                              />
                            );
                          }
                        })}
                    </td>
                    <td className={"pr-4"}>
                      <Button
                        className={cx("mr-4", "text-black")}
                        iconLeft={<GlyphSwap />}
                        onClick={(e?: React.MouseEvent) => {
                          e?.stopPropagation();
                          setStatusCandidateId(item.id);
                          setIsModalStatusEditOpen(true);
                          return;
                        }}
                        variant="secondary"
                      ></Button>
                      {isManagerOrWatcher ? (
                        ""
                      ) : (
                        <Button
                          className={cx("text-black")}
                          iconLeft={<GlyphBin />}
                          onClick={(e?: React.MouseEvent) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            setStatusCandidateId(item.id);
                            setIsModalDeleteCandidateOpen(true);
                            setSelectedItems((old: any) => {
                              const nValues = {
                                ...old,
                              };
                              delete nValues[item.id];
                              selectedHandle(item, false);
                              return nValues;
                            });
                          }}
                          variant="secondary"
                        ></Button>
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      )}

      {FilterBlockID &&
        ReactDOM.createPortal(
          <>
            <div className={style.MassOperationBlock}>
              <div className="mb-4">
                <Button
                  disabled={isResendResumeBtnDisabled}
                  loading={loadingFilter}
                  onClick={async () => {
                    changeResendResumeDisabledBtn(true);
                    changeIsLoadingFilter(true);
                    const personIds = getActiveManagers();

                    const getListManagers: any = await loadCandidatesData({
                      pageNum: 1,
                      pageSize: 999,
                      personIds,
                      vacancyIds: vacancy.id,
                    });

                    const ressendResume: any = [];
                    if (
                      getListManagers &&
                      getListManagers.data &&
                      getListManagers.data.items
                    ) {
                      getListManagers.data.items.forEach((manager: any) => {
                        let needAddManager = false;
                        manager.vacancies.forEach((vac: any) => {
                          if (vac.vacancyId === vacancy.id && vac.resumeSent) {
                            needAddManager = true;
                          }
                        });

                        if (needAddManager) ressendResume.push(manager);
                      });
                    }

                    if (ressendResume.length) {
                      changeShowPopupReplyMessage(true);
                      setSelectedItemsRessend(ressendResume);
                      changeIsLoadingFilter(false);
                    } else {
                      sendResumeFn();
                    }
                  }}
                >
                  Отправить резюме ({selectedItemsFilter.sendResume.length})
                </Button>
              </div>
              <div className="mb-2">
                <Button
                  disabled={isMassStatusesBtnDisabled}
                  loading={false}
                  onClick={async () => {
                    changeMassStatusesDisabledBtn(false);
                    setIsMassModalStatusEditOpen(true);
                  }}
                >
                  Сменить статус ({selectedItemsFilter.changeStatus.length})
                </Button>
              </div>
            </div>
          </>,
          FilterBlockID,
        )}

      {showPopupReplyMessage ? (
        <Popup
          classNameContent={cx(style.modal, "p-16")}
          classNameWrapper="p-0"
          onClickCloseCross={clickOnCloseReplyMessage}
          onClickOutside={clickOnCloseReplyMessage}
        >
          <div>
            <div className="text-center mb-4">
              Резюме уже направлялись нанимающему менеджеру. <br /> Выберите
              резюме для повторной отправки.
            </div>
            <div className={`mb-8 ${style.CandidateListAfterSelected}`}>
              {selectedItemsRessend.map((item: any, key: number) => {
                return (
                  <div
                    key={key}
                    className={"d-flex mb-8"}
                    onClick={() => {
                      const isSelected = !selectedItems[item.id];
                      setSelectedItems((old: any) => {
                        const nValues = {
                          ...old,
                          [item.id]: isSelected,
                        };
                        selectedHandle(item, isSelected);

                        return nValues;
                      });
                    }}
                  >
                    <div className={"mr-4"}>
                      <Checkbox
                        checked={selectedItems[item.id]}
                        onChange={() => {
                          const isSelected = selectedItems[item.id];
                          setSelectedItems((old: any) => {
                            const nValues = {
                              ...old,
                              [item.id]: isSelected,
                            };
                            selectedHandle(item, isSelected);

                            return nValues;
                          });
                        }}
                      />
                    </div>
                    <div>{item.fullName}</div>
                  </div>
                );
              })}
            </div>

            <div>
              <div className={`${style.btnGroup}`}>
                <Button
                  className="mr-4"
                  disabled={isLoadingModal || btnDisabledResend}
                  loading={isLoadingModal}
                  onClick={() => {
                    changeIsLoadingModal(true);
                    sendResumeFn();
                  }}
                >
                  Отправить
                </Button>
                <Button
                  disabled={isLoadingModal}
                  onClick={() => clickOnCloseReplyMessage()}
                  variant="secondary"
                >
                  Отменить
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      ) : null}
      {isModalMassStatusEditOpen && (
        <ChangeStatusPopup
          candidate={selectedItemsFilter.changeStatus}
          clickOnClose={(needUpdateList = false) => {
            setIsMassModalStatusEditOpen(false);
            clearAllChecked();
            if (needUpdateList) {
              updateCandidateData();
            }
          }}
          vacancy={{ id: vacancy.id }}
        />
      )}
      {isModalStatusEditOpen && (
        <ChangeStatusPopup
          candidate={{ id: statusCandidateId }}
          clickOnClose={(needUpdateList = false) => {
            setIsModalStatusEditOpen(false);
            if (needUpdateList) {
              updateCandidateData();
            }
          }}
          vacancy={{ id: vacancy.id }}
        />
      )}
      {isModalDeleteCandidateOpen && (
        <DeleteCandidateFromVacancy
          candidateId={statusCandidateId}
          clickOnClose={(needUpdateList = false) => {
            setIsModalDeleteCandidateOpen(false);
            if (needUpdateList) {
              updateCandidateData();
            }
          }}
          vacancy={vacancy}
        />
      )}
    </div>
  );
};

export default CandidatesList;
