import { PropertyPath } from "../types/services/snackbar/types";

const dictConflict = {
  email: "email",
  phone: "телефоном",
  telegramName: "ником в телеграм",
};
export const DICTIONARY = {
  BIND_CANDIDATE_VACANCY: (name: string, vacancyName = "") => {
    return `Кандидат ${name} добавлен к вакансии ${vacancyName}`;
  },
  CHANGE_CANDIDATES_STATUSES: "Кандидаты переведены в выбранный статус",
  CHANGE_CANDIDATE_STATUS: "Кандидат переведен в выбранный статус",
  //
  CHANGE_VACANCY: "Изменения в вакансии сохранены",
  CHANGE_VACANCY_STATUS: "Вакансия переведена в выбранный статус",
  CONFLICT_PERSON: "Кандидат с таким номером или email уже есть в базе.",
  CONFLICT_PROPERTY_PATH: function (
    propertyPath: PropertyPath,
    message: string,
  ) {
    return `Кандидат с таким ${dictConflict[propertyPath]} ${message} уже есть в базе.`;
  },
  DELETE_CANDIDATE: function (name: string) {
    return `Кандидат ${name} удален из базы`;
  },
  FAIL: "Сервис временно недоступен. Попробуйте позже",
  FAIL_ADDED_FILE_TO_CANDIDATE:
    "Файл с резюме не удалось привязать к кандидату. Вы можете прикрепить резюме при редактировании карточки кандидата",
  FAIL_BIND_TO_CANDIDATE:
    "Не удалось добавить кандидата к вакансии. Перейдите в карточку вакансии и добавьте нужного кандидата",
  FAIL_CREATE_ACCOUNT_WITH_THE_SAME_FIELDS:
    "Пользователь с таким логином, почтой или телефоном уже есть в системе",
  FAIL_RESUME_SEND: "Не удалось отправить резюме",
  FORBIDDEN: "Недостаточно прав на выполнение операции",
  LABEL_ADD_TO_CARD: "Метка добавлена к карточке",
  LABEL_EXCEPTION:
    "Не удалось привязать метку. Вы можете прикрепить ее при редактировании карточки",
  LABEL_REMOVE_FROM_CARD: "Метка удалена с карточки",
  NOT_VALID_EMAIL: "Некорректный e-mail",
  NOT_VALID_LOGIN: "Некорректный логин",
  NOT_VALID_PHONE: "Некорректный номер телефона",
  NOT_VALID_TELEGRAM: "Некорректный ник телеграм",
  SUCCESS_CANDIDATE: function (name: string, isUpdateCard: boolean) {
    return isUpdateCard
      ? `Карточка кандидата ${name} изменена.`
      : `Кандидат ${name} добавлен в базу`;
  },
  SUCCESS_RESUME: "Резюме успешно загружено",
  SUCCESS_RESUME_SEND: "Резюме успешно отправлено",
  SUCCESS_USER_CREATED: "Пользователь успешно создан",
  SUCCESS_VACANCY: "Вакансия создана",
  UNBIND_CANDIDATE_VACANCY: (name: string, vacancyName: string) => {
    return `Кандидат ${name} отвязан от вакансии ${vacancyName}`;
  },
  UNKNOWN_ERR: "Неизвестная ошибка",
};
